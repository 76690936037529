<template>
	<div class="thank_you">
		<Loader v-if="!reservation_id"></Loader>
		<div class="thank_you_button" v-if="reservation_id > 0" @click="exportToPDF">{{ getTranslation('DOWNLOAD_PDF_REPORT') }}</div>
		<div class="thank_you_inner" id="thank_you_pdf" v-if="reservation_id > 0">
			<h2 v-if="payment_method == 1">{{ getTranslation('BANK_TRANSFER_REQUEST') }}!</h2>
			<h2 v-else>{{ getTranslation('THANKYOU') }}:</h2>

			<div v-if="payment_method == 1" class="thank_you_section">
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>IBAN</label>
						<span>{{ iban }}</span>
					</div>
					<div class="thank_you_section_col">
						<label>{{ getTranslation('BANK') }}</label>
						<span>{{ banca }}</span>
					</div>
				</div>
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('QUOTA_AMOUNT') }}</label>
					</div>
					<div class="thank_you_section_col">
						<span>{{ amount }} €</span>
					</div>
				</div>
			</div>
			<div class="thank_you_section">
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('RESERVATION_NUMBER') }}</label>
					</div>
					<div class="thank_you_section_col">
						<span>BOL_{{ reservation_id }}</span>
					</div>
				</div>
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('CHECKIN') }}</label>
						<span>{{ check_in_formatted }}</span>
					</div>
					<div class="thank_you_section_col">
						<label>{{ getTranslation('CHECKOUT') }}</label>
						<span>{{ check_out_formatted }}</span>
					</div>
				</div>
			</div>
			<div class="thank_you_section">
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('NAME') }}</label>
						<span>{{ first_name }}</span>
					</div>
					<div class="thank_you_section_col">
						<label>{{ getTranslation('SURNAME') }}</label>
						<span>{{ last_name }}</span>
					</div>
				</div>
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('EMAIL') }}</label>
						<span>{{ email }}</span>
					</div>
					<div class="thank_you_section_col">
						<label>{{ getTranslation('PHONE') }}</label>
						<span>{{ tel }}</span>
					</div>
				</div>
				<div class="thank_you_section_row" v-if="notes != ''">
					<div class="thank_you_section_col full_width">
						<label>{{ getTranslation('REQUESTS') }}</label>
						<span>{{ notes }}</span>
					</div>
				</div>
			</div>
			<div class="thank_you_section" style="margin-bottom: 10px;" v-if="booking_type == 2">
				<div class="thank_you_section_row">
					<div class="thank_you_section_col">
						<label>{{ getTranslation('ADULT') }}</label>
						<span>{{ adults() }}</span>
					</div>
					<div class="thank_you_section_col" v-if="ages.child1_age != null">
						<label>{{ getTranslation('CHILD') }} <span>({{ ages.child1_age }})</span></label>
						<span>{{ child1() }}</span>
					</div>
					<div class="thank_you_section_col" v-if="ages.child2_age != null">
						<label>{{ getTranslation('CHILD') }} <span>({{ ages.child2_age }})</span></label>
						<span>{{ child2() }}</span>
					</div>
					<div class="thank_you_section_col" v-if="ages.infant_age != null">
						<label>{{ getTranslation('INFANT') }} <span>({{ ages.infant_age }})</span></label>
						<span>{{ infants() }}</span>
					</div>
				</div>
				<div class="thank_you_section_room">
					<a class="thank_you_section_room_image" v-bind:style="{ backgroundImage: 'url(' + package_image() + ')' }" :href="package_image()" data-lightbox="lightbox_thankyou">
						<span>{{ package_name() }}</span>
					</a>
					<div class="thank_you_section_room_description">
						<div>
							<h4>{{ getTranslation('PACKAGE') }} {{ nights }} {{ nights_text }}</h4>
							<h3 v-html="package_name()"></h3>
							<span v-html="package_description()"></span>
						</div>
					</div>
				</div>
				<div class="thank_you_section_room" style="margin-top: 30px;">
					<a class="thank_you_section_room_image" v-bind:style="{ backgroundImage: 'url(' + room_image1() + ')' }" :href="room_image1()" data-lightbox="lightbox_thankyou">
						<span>{{ room_name() }}</span>
					</a>
					<a class="image_gallery" v-if="room_image2()" :href="room_image2()" data-lightbox="lightbox_thankyou">#</a>
					<a class="image_gallery" v-if="room_image3()" :href="room_image3()" data-lightbox="lightbox_thankyou">#</a>
					<div class="thank_you_section_room_description">
						<div>
							<h4>{{ getTranslation('ROOM') }}</h4>
							<h3 v-html="room_name()"></h3>
							<span v-html="room_description()"></span>
						</div>
						<div>
							<h4 v-html="room_arrangement()"></h4>
							<h5 v-html="room_policy()"></h5>
							<span v-html="room_policy_description()"></span>
						</div>
						<div>
							<span>{{ room_price() }} €</span>
						</div>
					</div>
				</div>
				<div class="thank_you_section_extra_charges">
					<div class="thank_you_section_extra_charges_row" v-for="extra_charge in selected_extra_charges(0)" v-bind:key="extra_charge.IDSERVICE">
						<div class="thank_you_section_extra_charges_row_top">
							<h3 v-html="extra_charge.title"></h3>
							<span v-html="extra_charge.description"></span>
						</div>
						<div class="thank_you_section_extra_charges_row_bottom">
							<span>{{ extra_charge.quantity_selected }} x <b>{{ extra_charge.import_formatted }} €</b></span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="booking_type == 1">
				<div class="thank_you_section" style="margin-bottom: 10px;" v-for="(room, index) in rooms" v-bind:key="index">
					<div class="thank_you_section_row">
						<div class="thank_you_section_col">
							<label>{{ getTranslation('ADULT') }}</label>
							<span>{{ adults(index) }}</span>
						</div>
						<div class="thank_you_section_col" v-if="ages.child1_age != null">
							<label>{{ getTranslation('CHILD') }} <span>({{ ages.child1_age }})</span></label>
							<span>{{ child1(index) }}</span>
						</div>
						<div class="thank_you_section_col" v-if="ages.child2_age != null">
							<label>{{ getTranslation('CHILD') }} <span>({{ ages.child2_age }})</span></label>
							<span>{{ child2(index) }}</span>
						</div>
						<div class="thank_you_section_col" v-if="ages.infant_age != null">
							<label>{{ getTranslation('INFANT') }} <span>({{ ages.infant_age }})</span></label>
							<span>{{ infants(index) }}</span>
						</div>
					</div>
					<div class="thank_you_section_room" v-if="room.package_selected == ''">
						<img  :src="room_image1(index)" alt="" class="checkout_section_room_image">

						<div class="thank_you_section_room_description">
							<div>
								<h3 v-html="room_name(index)"></h3>
								<span v-html="room_description(index)"></span>
							</div>
							<div>
								<h4 v-html="room_arrangement(index)"></h4>
								<h5 v-html="room_policy(index)"></h5>
								<span v-html="room_policy_description(index)"></span>
							</div>
							<div>
								<span>{{ room_price(index) }} €</span>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="thank_you_section_room">
							<img v-if="package_image(index)" :src="package_image(index)" alt="" class="checkout_section_room_image">
							<div class="thank_you_section_room_description">
								<div> 
									<div>
										<h3>{{ package_name(index) }}</h3>
										<p v-html="package_description(index)"></p>
									</div>
								</div>
							</div>
						</div>
						<div class="thank_you_section_room" style="margin-top: 10px;">
							<img v-if="room_image1(index)" :src="room_image1(index)" alt="" class="checkout_section_room_image">
							<div class="thank_you_section_room_description">
								<div>
									<span>{{ getTranslation('ROOM') }}:</span>
									<h3 v-html="room_name(index)"></h3>
									<span v-html="room_description(index)"></span>
								</div>
								<div>
									<h5 v-html="room_policy(index)"></h5>
									<span v-html="room_policy_description(index)"></span>
								</div>
								<div>
									<span>{{ room_price(index) }} €</span>
								</div>
							</div>
						</div>
					</div>
					<div class="thank_you_section_extra_charges">
						<div class="thank_you_section_extra_charges_row" v-for="extra_charge in selected_extra_charges(index)" v-bind:key="extra_charge.IDSERVICE">
							<div class="thank_you_section_extra_charges_row_top">
								<h3 v-html="extra_charge.title"></h3>
								<span v-html="extra_charge.description"></span>
							</div>
							<div class="thank_you_section_extra_charges_row_bottom">
								<span>{{ extra_charge.quantity_selected }} x <b>{{ extra_charge.import_formatted }} €</b></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="thank_you_section" v-if="booking_type == 1 && coupon.status == 1" style="margin-bottom: 10px;">
				<div class="checkout_section_discount">
					<div class="checkout_section_discount_left">
						<span>{{ getTranslation('DISCOUNT_APPLIED') }}</span>
						<span><b>{{ coupon.data.DESCRIPTION }}</b> (-{{ parseInt(coupon.data.DISCOUNT) }}%)</span>
					</div>
					<div class="checkout_section_discount_right">
						{{ discount() }} €
					</div>
				</div>
			</div>
			<div class="thank_you_section">
				<div class="thank_you_section_total">
					<div>
						{{ getTranslation('TOTALPRICE') }}
					</div>
					<div>
						{{ total() }} €
					</div>
				</div>
			</div>
		</div>
		<div class="thank_you_inner" v-else>
			<div class="thank_you_error">{{ getTranslation('THANKYOUERROR') }}</div>
		</div>
		<div class="desktop_hidden">
			<hr/>
			<div class="summary_block_reset" @click="backToSearch()">
				{{ getTranslation('NEWSEARCH') }}
			</div>
		</div>
	</div>
</template>

<script>
import Loader from './Loader.vue'
import html2pdf from "html2pdf.js";

export default {
	name: 'ThankYou',
	components: {
		Loader
	},
	computed: {
		payment_method: function() {
			return this.$store.state.payment_bonifico;
		},
		iban: function() {
			return this.$store.state.company_info.iban;
		},
		banca: function() {
			return this.$store.state.company_info.banca;
		},
		amount: function() {
			return this.$store.state.amount_bonifico;
		},
		ages: function() {
			return this.$store.state.ages;
		},
		reservation_id: function() {
			return this.$store.state.reservation_id;
		},
		first_name: function() {
			return this.$store.state.checkout_data.first_name;
		},
		last_name: function() {
			return this.$store.state.checkout_data.last_name;
		},
		email: function() {
			return this.$store.state.checkout_data.email;
		},
		tel: function() {
			return this.$store.state.checkout_data.tel;
		},
		notes: function() {
			return this.$store.state.checkout_data.notes;
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		check_in_formatted: function() {
			return this.$formatDateIt(this.check_in.replace(/-/g, '/'));
		},
		check_out: function() {
			return this.$store.state.last_search.check_out;
		},
		check_out_formatted: function() {
			return this.$formatDateIt(this.check_out.replace(/-/g, '/'));
		},
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		rooms: function() {
			return this.$store.state.rooms;
		},
		nights: function() {
			return this.$calculateNights(new Date(this.$store.state.check_in), new Date(this.$store.state.check_out));
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		},
		coupon: function() {
			return this.$store.state.coupon;
		}
	},
	created: function() {
		if(this.$store.state.tracking_codes['TRACKING_SCRIPT_CONVERSION'] != undefined) {
			let tracking_code = this.$store.state.tracking_codes['TRACKING_SCRIPT_CONVERSION'];

			if(this.booking_type == 1) {
				let travelers  = 0;
				let price = 0;
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.rooms[index].policy_selected.TOTAL_PRICE != undefined) {
						travelers += this.rooms[index].adults + this.rooms[index].child1 + this.rooms[index].child2 + this.rooms[index].infants;
						price += this.rooms[index].policy_selected.TOTAL_PRICE;
					}
				}

				let gtag_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.room_ID(index) != undefined) {
						gtag_items += `
							{
								"id": `+this.room_ID(index)+`,
								"name": "`+this.room_name(index)+`",
								"quantity": 1,
								"price": "`+this.room_price_not_formatted(index).toFixed(2)+`"
							},
						`;
					}
				}
				tracking_code = tracking_code.replace(/%gtagitems%/g, gtag_items);

				let piwik_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.room_ID(index) != undefined) {
						piwik_items += `
							{
								sku: '`+this.room_ID(index)+`',
								name: '`+this.room_name(index)+`',
								price: `+this.room_price_not_formatted(index).toFixed(2)+`,
								quantity: 1,
							},
						`;
					}
				}
				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, price.toFixed(2));

				tracking_code = tracking_code.replace(/%id/g, this.reservation_id);
				tracking_code = tracking_code.replace(/%ci/g, this.$store.state.last_search.check_in);
				tracking_code = tracking_code.replace(/%co/g, this.$store.state.last_search.check_out);
				tracking_code = tracking_code.replace(/%rn/g, '');
				tracking_code = tracking_code.replace(/%r/g, this.rooms.length);
				tracking_code = tracking_code.replace(/%t/g, travelers);
				tracking_code = tracking_code.replace(/%p/g, price.toFixed(2));
			} else if(this.booking_type == 2) {
				let gtag_items = `
					{
						"id": `+this.room_ID(0)+`,
						"name": "`+this.room_name(0)+`",
						"quantity": 1,
						"price": "`+this.room_price_not_formatted(0).toFixed(2)+`"
					}
				`;
				tracking_code = tracking_code.replace(/%gtagitems%/g, gtag_items);

				let piwik_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					piwik_items += `
						{
							sku: '`+this.room_ID(0)+`',
							name: '`+this.room_name(0)+`',
							price: `+this.room_price_not_formatted(0).toFixed(2)+`,
							quantity: 1,
						},
					`;
				}
				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, this.room_price_not_formatted(0).toFixed(2));

				tracking_code = tracking_code.replace(/%id/g, this.reservation_id);
				tracking_code = tracking_code.replace(/%ci/g, this.$store.state.last_search.check_in);
				tracking_code = tracking_code.replace(/%co/g, this.$store.state.last_search.check_out);
				tracking_code = tracking_code.replace(/%rn/g, this.room_name(0));
				tracking_code = tracking_code.replace(/%r/g, '1');
				tracking_code = tracking_code.replace(/%t/g, this.adults(0) + this.child1(0) + this.child2(0) + this.infants(0));
				tracking_code = tracking_code.replace(/%p/g, this.room_price_not_formatted(0).toFixed(2));
			}
			
			eval(tracking_code);
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		backToSearch: function() {
			window.location.reload();
		},
		adults: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].adults;
			} else {
				return this.$store.state.package.adults;
			}
		},
		child1: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].child1;
			} else {
				return this.$store.state.package.child1;
			}
		},
		child2: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].child2;
			} else {
				return this.$store.state.package.child2;
			}
		},
		infants: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].infants;
			} else {
				return this.$store.state.package.infants;
			}
		},
		package_name: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].package_selected['PACKAGE_NAME_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_selected['NAME_'+this.$store.getters.get_lang_code];
			}
		},
		package_image: function(index = 0) {
			let image;
			if(this.booking_type == 1) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.rooms[index].package_selected['IDCOMPANY']+'/packages/' + this.$store.state.rooms[index].package_selected['PACKAGE_ID'] + '/' + this.$store.state.rooms[index].package_selected['PACKAGE_IMAGE']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else if(this.booking_type == 2) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+ this.$store.state.package.package_selected.IDCOMPANY +'/packages/' + this.$store.state.package.package_selected.ID + '/' + this.$store.state.package.package_selected.IMAGE);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			}
		},
		package_description: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].package_selected['PACKAGE_DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
		},
		room_ID: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['ID'];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['ID'];
			}
			return 0;
		},
		room_name: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['NAME_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['NAME_'+this.$store.getters.get_lang_code];
			}
			return '';
		},
		room_image1: function(index) {
			let image;
			if(this.booking_type == 1) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE1']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else if(this.booking_type == 2) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE1']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			}
			return '';
		},
		room_image2: function(index) {
			let image;
			if(this.booking_type == 1) {
				if(this.$store.state.rooms[index].room_selected['IMAGE2'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE2']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			} else if(this.booking_type == 2) {
				if(this.$store.state.package.package_room_selected['IMAGE2'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE2']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			}
			return false;
		},
		room_image3: function(index) {
			let image;
			if(this.booking_type == 1) {
				if(this.$store.state.rooms[index].room_selected['IMAGE3'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE3']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			} else if(this.booking_type == 2) {
				if(this.$store.state.package.package_room_selected['IMAGE3'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE3']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			}
			return false;
		},
		room_description: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
			return '';
		},
		room_arrangement: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['ARRANGEMENT_NAME_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_DESCRIPTIONSERVICES_'+this.$store.getters.get_lang_code];
			}
		},
		room_policy: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['POLICY_NAME_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_NAME_'+this.$store.getters.get_lang_code];
			}
		},
		room_policy_description: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
		},
		room_price_not_formatted: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected.TOTAL_PRICE;
			}
			return '';
		},
		room_price: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE_FORMATTED;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected.TOTAL_PRICE_FORMATTED;
			}
			return '';
		},
		selected_extra_charges: function(index) {
			let extra_charges = [];
			let self = this;

			let extra_charges_arr = [];
			if(this.booking_type == 1) {
				extra_charges_arr = this.$store.getters.selected_extra_charges(index);
			} else if(this.booking_type == 2) {
				extra_charges_arr = this.$store.getters.selected_package_extra_charges;
			}

			extra_charges_arr.forEach(function(extra_charge) {
				let has_image = false;
				if(extra_charge.IMAGE != '') {
					has_image = true;
				}
				let image = encodeURI(self.$baseURL + '/structures/'+self.$company+'/'+self.$store.state.company_id+'/services/' + extra_charge.IDSERVICE + '/' + extra_charge.IMAGE);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");

				let title = extra_charge['NAME_'+self.$store.getters.get_lang_code];
				let description = extra_charge['DESCRIPTION_'+self.$store.getters.get_lang_code];

				extra_charges.push({
					has_image: has_image,
					image: image,
					title: title,
					description: description,
					quantity_selected: extra_charge.QUANTITY_SELECTED,
					import_formatted: extra_charge.IMPORT_FORMATTED
				});
			});
			return extra_charges;
		},
		discount_not_formatted: function() {
			let discount = 0;
			if(this.booking_type == 1) {
				for(let index = 0; index < this.$store.state.rooms.length; index++) {
					discount += this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
					let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
					for(let i = 0; i < selected_extra_charges.length; i++) {
						discount += selected_extra_charges[i].DISCOUNT * selected_extra_charges[i].QUANTITY_SELECTED;
					}
				}
			}
			return discount;
		},
		discount: function() {
			return this.discount_not_formatted().toFixed(2).replace(".", ",");
		},
		total_not_formatted: function() {
			let total = 0;
			if(this.booking_type == 1) {
				for(let index = 0; index < this.$store.state.rooms.length; index++) {
					total += this.$store.state.rooms[index].policy_selected.TOTAL_PRICE + this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
					let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
					for(let i = 0; i < selected_extra_charges.length; i++) {
						if(selected_extra_charges[i].DISCOUNT){
							total += (selected_extra_charges[i].IMPORT + selected_extra_charges[i].DISCOUNT) * selected_extra_charges[i].QUANTITY_SELECTED;
						}
						else {
							total += selected_extra_charges[i].IMPORT * selected_extra_charges[i].QUANTITY_SELECTED;
						}
					}
				}
			} else if(this.booking_type == 2) {
				total = this.$store.state.package.package_room_selected.TOTAL_PRICE;
				for(let i = 0; i < this.$store.getters.selected_package_extra_charges.length; i++) {
					total += this.$store.getters.selected_package_extra_charges[i].IMPORT * this.$store.getters.selected_package_extra_charges[i].QUANTITY_SELECTED;
				}
			}
			return total;
		},
		total: function() {
			return this.total_not_formatted().toFixed(2).replace(".", ",");
		},
		exportToPDF() {
			let doc_width = 8.27;  // A4 measures 210 × 297 millimeters or 8.27 × 11.69 inches
			let doc_height = 11.69;
			let dpi = 88; // targeting ~1200 window width
			let img_width = doc_width * dpi;
			//let img_height = doc_height * dpi;
			let win_width = img_width;
			//let win_height = img_height;

			let html2canvasOpts = {
				scale: img_width/win_width,   // equals 1
				width: img_width,
				//height: img_height,
				windowWidth: win_width,
				//windowHeight: win_height,
			};

			let jsPDFOpts = {
				orientation: 'portrait',
				unit: 'in',
				format: [doc_width, doc_height]
			};

			html2pdf().set({
				margin: [0.4, 0.4, 0.4, 0.4],
				filename: 'reservation.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: html2canvasOpts,
				jsPDF: jsPDFOpts,
				pagebreak: { mode: ['css'] }
			}).from(this.$store.getters.confirmation_pdf_html).save();
		}
	}
}
</script>