<template>
	<div class="sidebar_summary">
		<div class="summary_block_1">
			<div class="summary_block_1_row">
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKIN') }}</span>
					{{ check_in_formatted }}
				</div>
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKOUT') }}</span>
					{{ check_out_formatted }}
				</div>
			</div>
		</div>
		<div class="summary_block_room" v-for="(room, index) in $store.state.rooms" v-bind:key="index">
			<div class="summary_block_room_name">{{ getTranslation('ROOM') }} {{ index + 1 }}</div>
			<div v-if="room.room_selected != ''">
				<div class="summary_block_1_row">
					<div class="summary_block_1_col label_2_rows">
						<span>{{ getTranslation('ADULT') }}</span>
						{{ adults(index) }}
					</div>
					<div class="summary_block_1_col label_2_rows" v-if="ages.child1_age != null">
						<span>{{ getTranslation('CHILD') }}<br/><span>{{ ages.child1_age }}</span></span>
						{{ child1(index) }}
					</div>
					<div class="summary_block_1_col label_2_rows" v-if="ages.child2_age != null">
						<span>{{ getTranslation('CHILD') }}<br/><span>{{ ages.child2_age }}</span></span>
						{{ child2(index) }}
					</div>
					<div class="summary_block_1_col label_2_rows" v-if="ages.infant_age != null">
						<span>{{ getTranslation('INFANT') }}<br/><span>{{ ages.infant_age }}</span></span>
						{{ infants(index) }}
					</div>
				</div>
				<div class="summary_block_2" v-if="room.package_selected == ''">
					<div class="summary_block_2_name" v-html="room_name(index)"></div>
					<div class="summary_block_2_arrangement" v-html="room_arrangement(index)"></div>
					<div class="summary_block_2_price">{{ room_price(index) }} €</div>
				</div>
				<div class="summary_block_2" v-else>
					<div class="summary_block_2_name" v-html="room_name(index)"></div>
					<div class="summary_block_2_arrangement" v-html="package_name(index)"></div>
					<div class="summary_block_2_price">{{ room_price(index) }} €</div>
				</div>
				<div class="summary_block_3" v-if="selected_extra_charges(index).length > 0">
					<div class="summary_block_3_row" v-for="extra_charge in selected_extra_charges(index)" v-bind:key="extra_charge.IDSERVICE">
						<div class="summary_block_3_name" v-html="getExtraChargeName(extra_charge)"></div>
						<div class="summary_block_3_price">
							{{ extra_charge.QUANTITY_SELECTED }} x <b>{{ extra_charge.IMPORT_FORMATTED }} €</b>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				Camera ancora non selezionata
			</div>
		</div>
		<div class="summary_block_discount" v-if="coupon.status == 1">
			<div>{{ getTranslation('DISCOUNT_APPLIED') }}:</div>
			<div>{{ discount() }} €</div>
		</div>
		<div class="summary_block_total">
			<div>{{ getTranslation('TOTALPRICE') }}:</div>
			<div>{{ total() }} €</div>
		</div>
		<hr/>
		<div class="summary_block_reset" @click="backToSearch()">
			{{ getTranslation('NEWSEARCH') }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'SidebarSummary',
	computed: {
		ages: function() {
			return this.$store.state.ages;
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		check_in_formatted: function() {
			return this.$formatDateIt(this.check_in.replace(/-/g, '/'));
		},
		check_out: function() {
			return this.$store.state.last_search.check_out;
		},
		check_out_formatted: function() {
			return this.$formatDateIt(this.check_out.replace(/-/g, '/'));
		},
		coupon: function() {
			return this.$store.state.coupon;
		}
	},
	methods: {
		adults: function(index) {
			return this.$store.state.rooms[index].adults;
		},
		child1: function(index) {
			return this.$store.state.rooms[index].child1;
		},
		child2: function(index) {
			return this.$store.state.rooms[index].child2;
		},
		infants: function(index) {
			return this.$store.state.rooms[index].infants;
		},
		package_name: function(index) {
			return this.$store.state.rooms[index].package_selected['PACKAGE_NAME_'+this.$store.getters.get_lang_code];
		},
		room_name: function(index) {
			return this.$store.state.rooms[index].room_selected['NAME_'+this.$store.getters.get_lang_code];
		},
		room_description: function(index) {
			return this.$store.state.rooms[index].room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		room_arrangement: function(index) {
			return this.$store.state.rooms[index].policy_selected['ARRANGEMENT_NAME_'+this.$store.getters.get_lang_code];
		},
		room_policy: function(index) {
			return this.$store.state.rooms[index].policy_selected['POLICY_NAME_'+this.$store.getters.get_lang_code];
		},
		room_price: function(index) {
			return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE_FORMATTED;
		},
		selected_extra_charges: function(index) {
			return this.$store.getters.selected_extra_charges(index);
		},
		discount_not_formatted: function() {
			let discount = 0;
			for(let index = 0; index < this.$store.state.rooms.length; index++) {
				discount += this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
				let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
				for(let i = 0; i < selected_extra_charges.length; i++) {
					discount += selected_extra_charges[i].DISCOUNT * selected_extra_charges[i].QUANTITY_SELECTED;
				}
			}
			return discount;
		},
		discount: function() {
			return this.discount_not_formatted().toFixed(2).replace(".", ",");
		},
		total: function() {
			let total = 0;
			for(let index = 0; index < this.$store.state.rooms.length; index++) {
				if(!isNaN(this.$store.state.rooms[index].policy_selected.TOTAL_PRICE)) {
					total += this.$store.state.rooms[index].policy_selected.TOTAL_PRICE + this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
				}
				let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
				for(let i = 0; i < selected_extra_charges.length; i++) {
					if(selected_extra_charges[i].DISCOUNT){
						total += (selected_extra_charges[i].IMPORT + selected_extra_charges[i].DISCOUNT) * selected_extra_charges[i].QUANTITY_SELECTED;
					}
					else {
						total += selected_extra_charges[i].IMPORT * selected_extra_charges[i].QUANTITY_SELECTED;
					}
				}				
			}
			return total.toFixed(2).replace(".", ",");
			
		},
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		getExtraChargeName: function(extra_charge) {
			return extra_charge['NAME_'+this.$store.getters.get_lang_code];
		},
		getExtraChargeDescription: function(extra_charge) {
			return extra_charge['DESCRIPTION_'+this.$store.getters.get_lang_code];
		},
		backToSearch: function() {
			this.$store.dispatch('backToSearch');
		}
	}
}
</script>