<template>
    <div>
        <!-- RIEPILOGO CHECKIN, CHECKOUT, #ALLOGGI, #OSPITI -->         
        <div class="quota_load_section" style="padding: 0; margin-top: 15px;" v-if="total_lodge[0] != 0 && commonData == 1 && dayDetailGlobal == 1">
            <div class="quota_load_section_row">
                
                <div class="quota_load_section_general_col">
                    <label>{{ getTranslation('CHECKIN') }}</label>
                    <span>{{ checkin[0] }}</span>
                </div>
                <div class="quota_load_section_general_col">
                    <label>{{ getTranslation('CHECKOUT') }}</label>
                    <span>{{ checkout[0] }}</span>
                </div>
                <div class="quota_load_section_general_col">
                    <label>{{ getTranslation('LODGE') }}</label>
                    <span>{{ total_lodge[0] }}</span>
                </div>
                <div class="quota_load_section_general_col">
                    <label>{{ getTranslation('GUESTS') }}</label>
                    <span>{{ total_guest[0] }}</span>
                </div>   
            </div>
        </div>
        <!-- CARD CONTENENTE OGNI TIPOLOGIA DI CAMERA PRESENTE NEL CORRENTE NR.CHOICE -->
        <div class="quota_load_section_card" v-for="(quotas, key) in quotas" :key="key">
            <!-- SE è UN PACCHETTO -->
            <div class="quota_load_section_room" v-if="quotas.IDPACKAGE != 0 && quotas.QTY != null" >
                <img v-if="imagePack(key)" :src="imagePack(key)" alt="" class="quota_load_section_room_image">
                <div class="quota_load_section_room_description">
                    <div>
                        <h3 class="targhetta">{{ total_nights[key] }} {{ getTranslation('NIGHTS') }}</h3>
                        <h3>{{ quotas.PACK}}</h3>
                        <div class="description_inline" @click.prevent="roomDescription(key)">
                            <h4 v-if="quotas.QTY > 1">{{ quotas.QTY }} x</h4><h4>{{ quotas['NAME']}}</h4><i class="fas fa-info-circle"></i>                            
                        </div>
                        <div class="policy">
                            <span>
                                <!-- {{ quotas['POLICY'] }} - -->
                                {{ quotasPolicy[key]['policyName'] }} -
                            </span>
                            <a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup(key)" href="">
                                {{ getTranslation('QUOTA_POLICY') }}
                            </a>
                        </div>
                        <div class="nr_guests">
                            <span><i class="fas fa-user-friends"></i> {{ quotas.NRADULT }} {{ getTranslation('ADULT') }}</span>
                            <span v-if="quotas.NRCHILD1 > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ quotas.NRCHILD1 }}</b></span>
                            <span v-if="quotas.NRCHILD2 > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ quotas.NRCHILD2 }}</b></span>
                            <span v-if="quotas.NRINFANT > 0"><i class="fas fa-baby"></i> {{ getTranslation('INFANT') }}: <b>{{ quotas.NRINFANT }}</b></span>
                        </div>
                        <br>
                        <span v-html="quotas.PACKEX"></span>
                    </div>
                    <div class="room_price">
                        <div class="room_price_inline">
                            <del class="secondario" v-if="quotas.CORRECTIONRATE != 0.00 && totalNoDiscountPackage(total[key], quotas.CORRECTIONRATE) != ''">{{ totalNoDiscountPackage(total[key], quotas.CORRECTIONRATE) }} €</del>
                            <label>{{ totalFormatPrice(total[key], quotas.QTY) }}</label>
                        </div>
                        <span>{{ getTranslation('PRICEFOR') }} {{ total_nights[key] }} {{ nights_text(total_nights[key]) }}</span>
                    </div>
                </div>
            </div>
            
            <!-- SE è UNA CAMERA NORMALE -->
            <div class="quota_load_section_room" v-if="quotas.IDPACKAGE == 0 && quotas.QTY != null">
                
                <img v-if="image1(key)" :src="image1(key)" alt="" class="quota_load_section_room_image" @click.prevent="roomDescription(key)">
                <div class="quota_load_section_room_description">
                    <div>                         
                        <div class="description_inline" v-if="commonData != 1">
                            <h4>{{ getTranslation('FROM') }} {{ checkin[key] }} {{ getTranslation('TO') }} {{ checkout[key] }}</h4> 
                        </div>

                        <div class="description_inline" @click.prevent="roomDescription(key)" v-if="quotas.VIEWTYPE == 0 || dayDetail(quotas.IDROOM) == 1">
                            <h3 v-if="quotas.QTY > 1">{{ formatQTY(key) }} x</h3><h3>{{ quotas['NAME']}}</h3><i class="fas fa-info-circle" style="color: var(--mainColor);"></i>
                        </div>
                        <div class="policy">
                            <span>
                                
                                {{ quotasPolicy[key]['policyName'] }} -
                            </span>
                            <a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup(key)" href="">
                               {{ getTranslation('QUOTA_POLICY') }}
                            </a>
                        </div>

                        <!-- tabella se i dettagli per ogni camera sono diversi -->
                        <table class="policy_detail" v-if="dayDetail(quotas.IDROOM) != 1">
                            <tr>
                                <th>{{ getTranslation('DAY') }}</th>
                                <th>{{ getTranslation('GUESTS') }}</th>
                                <th>{{ getTranslation('QUOTA_PREPARATION') }}</th>
                                <th>{{ getTranslation('QUOTA_QTY') }}</th>
                                <th>{{ getTranslation('QUOTA_ARRANGEMENT') }}</th>
                                <th>{{ getTranslation('PRICE') }}</th>
                            </tr>
                            <tr v-for="(quotasDaysRow, key) in quotasDays(quotas.IDROOM)" :key="key">
                                <td>{{ formatDay(quotasDaysRow.DAY) }}</td>
                                <td style="display: flex; flex-direction: column;">
                                    <span>{{ quotasDaysRow.NRADULT }} {{ adults_text(quotasDaysRow.NRADULT) }}</span>
                                    <span v-if="quotasDaysRow.NRCHILD1 > 0">{{ quotasDaysRow.NRCHILD1 }} {{ child1_text(quotasDaysRow.NRCHILD1) }}</span>
                                    <span v-if="quotasDaysRow.NRCHILD2 > 0">{{ quotasDaysRow.NRCHILD2 }} {{ child2_text(quotasDaysRow.NRCHILD2) }}</span>
                                    <span v-if="quotasDaysRow.NRINFANT > 0">{{ quotasDaysRow.NRINFANT }} {{ infants_text(quotasDaysRow.NRINFANT) }}</span>
                                </td>
                                <td>{{ quotasDaysRow['ACCOMODATIONNAME'] }}</td>
                                <td>{{ quotasDaysRow['QTY'] }}</td>
                                <td style="cursor: pointer;" @click.prevent="arrangementDescription(quotasDaysRow['ROOM_ARRANGEMENT'])">{{quotasDaysRow['ROOM_ARRANGEMENT_NAME'] }} <i class="fas fa-info-circle" style="color: var(--mainColor);"></i></td>
                                <td>{{ formatPrice(quotasDaysRow['TOTALPRICE']) }}</td>
                            </tr>
                        </table>

                        <div class="arrangement" v-if="dayDetail(quotas.IDROOM) == 1">
                            <i class="fas fa-concierge-bell"></i>
                            <span v-html="quotas['ROOM_ARRANGEMENT']"></span>
                        </div>
                        <div class="nr_guests" v-if="dayDetail(quotas.IDROOM) == 1">
                            <span><i class="fas fa-user-friends"></i> {{ quotas.NRADULT }} {{ getTranslation('ADULT') }}</span>
                            <span v-if="quotas.NRCHILD1 > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ quotas.NRCHILD1 }}</b></span>
                            <span v-if="quotas.NRCHILD2 > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ quotas.NRCHILD2 }}</b></span>
                            <span v-if="quotas.NRINFANT > 0"><i class="fas fa-baby"></i> {{ getTranslation('INFANT') }}: <b>{{ quotas.NRINFANT }}</b></span>
                        </div>
                    </div>
                    <div class="room_price">
                        <div class="room_price_inline">
                            <!-- <del class="secondario" v-if="quotas.CORRECTIONRATE != 0.00 && totalNoDiscount(total[key], quotas.CORRECTIONRATE * quotas.QTY * total_nights[key], quotas.DISCOUNTRATE) != ''">{{ totalNoDiscount(total[key], quotas.CORRECTIONRATE * quotas.QTY * total_nights[key], quotas.DISCOUNTRATE) }} €</del> -->
                            <del class="secondario" v-if="quotas.CORRECTIONRATE != 0.00 && totalNoDiscount(key) != ''">{{ formatPrice(totalNoDiscount(key)) }}</del>
                            <label>{{ formatPrice(total[key]) }}</label>
                        </div>
                        <span>{{ getTranslation('PRICEFOR') }} {{ total_nights[key] }} {{ nights_text(total_nights[key]) }}</span>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- SEZIONE PER SERVIZI AGGIUNTIVI -->
        <QuotaLoadServices 
            v-if="controlServices()"
        ></QuotaLoadServices>
        <!-- SEZIONE GRATUITA' -->
         <div class="quota_load_section" v-if="quotas[0].NRFREE != 0">
            <div class="quota_load_section_total">
                <h2>
                    {{ getTranslation('QUOTA_GRATUITY') }}
                </h2>
                <h2> -
                    <span>{{ quotas[0].NRFREE }} x </span>
                    {{ formatPrice(quotas[0].TOTALFREE) }}
                </h2>
            </div>
        </div>        
        <!-- SEZIONE SPESA TOTALE -->
        <div class="quota_load_section">
            <div class="quota_load_section_total">
                <h2>
                    {{ getTranslation('TOTALPRICE') }}
                </h2>
                <h2>
                    {{ formatPrice(total_price) }}
                </h2>
            </div>
        </div>
        <!-- SEZIONE ACCONTO -->
        <div class="quota_load_section" v-if="amount != '0.00'">
            <div class="quota_load_section_total">
                <h2>
                    {{ getTranslation('QUOTA_AMOUNT') }}
                </h2>
                <h2>
                    {{ formatPrice(amount) }}
                </h2>
            </div>
        </div>

        <!-- SEZIONE PAGAMENTO CON CARTA DI CREDITO -->
        <QuotaLoadPayment
            v-if="status_check == 1 && !isPastDate"        
            :validation_token = validation_token
        ></QuotaLoadPayment>

        <!-- BOTTONE PAGAMENTO NEXI -->
<!--         <div class="quota_load_button_container" v-if="payment_methods.nexi && status_check == 1 && !isPastDate">
            <div class="quota_load_button" @click="acceptQuota()">{{ getTranslation('QUOTA_ACCEPT') }}</div>
        </div> -->

        <!-- POP-UP -->
        <Modal :isVisible="show_modal" @close="closeModal">
            <div v-html="responseHtml" v-if="responseHtml"></div>
        </Modal>
        
    </div>
</template>

<script>

    import QuotaLoadServices from './QuotaLoadServices.vue';
    import Modal from './Modal.vue';
    import QuotaLoadPayment from './QuotaLoadPayment.vue';

    export default {
        name: 'Quotas',

        props: ['validation_token', 'status_check', 'isPastDate'],

        components: {
            QuotaLoadServices,
            Modal,
            QuotaLoadPayment
        },

        data: function() {
            return{
                responseHtml: null,
                show_modal: false,
                payment_methods: '',                
            }
        },

        computed: {
            ages: function() {
                return this.$store.state.ages;
            },
            setCodeLang: function() {
                return String(this.$store.getters.get_lang_code);
            },
            lang: function() {
                return this.$store.state.lang;
            },
            quota_id: function() {
                return  this.$store.state.quota.quota_id;
            },
            nrchoice: function() {
                return this.$store.state.quota.nrchoice;
            },
            quotas: function() {
                var nrchoice = this.nrchoice;                
                return this.$store.state.quotas[nrchoice];
            },
            newQty: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_newQty[nrchoice];
            },
            checkin: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_checkin[nrchoice];
            },
            checkout: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_checkout[nrchoice];
            },
            total: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total[nrchoice];
            },
            total_correctionrate: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_correctionrate[nrchoice];
            },
            total_nights: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_nights[nrchoice];
            },
            total_lodge: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_lodge[nrchoice];
            },
            total_firstdayprice: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_firstdayprice[nrchoice];
            },
            total_price: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_price[nrchoice];
            },
            total_guest: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_total_guest[nrchoice];
            },
            amount: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_amount[nrchoice];
            },
            additional_services: function() {
                return this.$store.state.quotas_services;
            },
            commonData: function() {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_commonData[nrchoice];
            },            
            dayDetailGlobal: function() {
                
                var nrchoice = this.nrchoice;
                var obj = this.$store.state.quotas_dayDetail[nrchoice];
                
                var ret = 1;
                Object.keys(obj).forEach(function(key){
                    if (obj[key] == 0){
                            ret = 0;                            
                    }                    
                })
                return  ret;
                
            },
            quotasPolicy: function() {
                var nrchoice = this.nrchoice;                           
                return this.$store.state.quotas_policyDescription[nrchoice]; 
            }         
        },

        methods: {
            formatDay(day) {                
                return this.$formatDateLocaleShort(day);
            },
            getTranslation: function(key) {
                return this.$store.getters.get_translation(key);
            },
            dayDetail: function(idroom) {
                var nrchoice = this.nrchoice;
                return this.$store.state.quotas_dayDetail[nrchoice][idroom];
            },
            quotasDays: function(idroom) {
                var nrchoice = this.nrchoice;              
                return this.$store.state.quotasdays[nrchoice][idroom];
            },
            adults_text: function(adults) {
                if(adults == 1) {
                    return this.getTranslation('ADULT_SINGLE');
                } else {
                    return this.getTranslation('ADULT');
                }
            },
            child1_text: function(child1) {
                if(child1 == 1) {
                    return this.getTranslation('CHILD_SINGLE');
                } else {
                    return this.getTranslation('CHILD');
                }
            },
            child2_text: function(child2) {
                if(child2 == 1) {
                    return this.getTranslation('CHILD_SINGLE');
                } else {
                    return this.getTranslation('CHILD');
                }
            },
            infants_text: function(infants) {
                if(infants == 1) {
                    return this.getTranslation('INFANT_SINGLE');
                } else {
                    return this.getTranslation('INFANT');
                }
            },   
            termsPopup: function(key) {
                window.$(`
                    <div class="privacy_popup" id="policy_popup">
                        `+this.quotasPolicy[key]['policyDescription']+`
                    </div>
                `).appendTo('body').modal();
            },
            roomDescription: function (key) {
                const imageTag = this.image1(key)
                    ? `<img src="${this.image1(key)}" alt="camera" class="popup_image">`
                    : '';

                const description = `
                    <div class="privacy_popup" id="room_popup">
                        ${imageTag}
                        <div>
                            ${this.quotas[key]['DESCRIPTION']}
                        </div>
                    </div>
                `;

                window.$(description).appendTo('body').modal();
            },
            arrangementDescription: function(string) {
                window.$(`
                    <div class="privacy_popup" id="policy_popup">
                        `+string+`
                    </div>
                `).appendTo('body').modal();
            },
            image1: function(key) {

                if (!this.quotas[key].IMAGE1 || this.quotas[0].VIEWFOTO == 0) {
                    return null; 
                }
                let image;
                image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.quotas[key].IDCOMPANYROOM+'/rooms/' + this.quotas[key].IDROOMTYPE + '/' + this.quotas[key].IMAGE1);
                image =  image.replace("(", "%28");
                image = image.replace(")", "%29");
                
                return image;
            },
            imagePack: function(key) {
                if (!this.quotas[key].IMAGEPACK || this.quotas[key].VIEWFOTO == 0) {
                    return null; 
                }

                var image = encodeURI(this.$baseURL + '/structures/' + this.$company+'/' +this.quotas[key].IDCOMPANYPACK + '/packages/' + this.quotas[key].IDPACKAGE + '/' + this.quotas[key].IMAGEPACK);
                image =  image.replace("(", "%28");
                image = image.replace(")", "%29");
                return image;
            },
            formatPrice(price) {

                let euroFormat = new Intl.NumberFormat('it-IT', {style: 'currency',currency: 'EUR'});
                return euroFormat.format(price);
                /* let number = parseFloat(price);
                return number.toFixed(2).replace('.', ','); */
            },
            totalFormatPrice(price, qty) {
                return (this.formatPrice(price*qty));
                /*let number = parseFloat(price) * qty;
                return number.toFixed(2).replace('.', ','); */
            },
            totalNoDiscount(key) {
                let totale = this.total[key]
                let correctionrate = this.total_correctionrate[key]

                let noDiscountTotal = totale + (-correctionrate)

                if(totale == 0 || totale >= noDiscountTotal){
                    return ''
                }

                return noDiscountTotal
            },
            totalNoDiscountPackage(price, correction) {

                let totale = Number(price) + (-correction)

                if(totale == 0 || totale <= price){
                    return ''
                }
                let formatTotale = parseFloat(totale);
                return formatTotale.toFixed(2).replace('.', ',');
            },
            formatQTY(key) {
                if(this.quotas[key]['VIEWTYPE'] == 1){
                    let accomodation = this.quotas[key].IDROOM
                     let QTY = this.newQty[accomodation]
                    return QTY 
                }else{
                    return this.quotas[key].QTY
                }
                
            },
            nights_text: function(nights) {
                if(nights == 1) {
                    return this.getTranslation('NIGHT').toLowerCase();
                } else {
                    return this.getTranslation('NIGHTS').toLowerCase();
                }
            },
            openModal() {
                this.show_modal = true; 
            },
            closeModal() {
                this.show_modal = false; 
            },
            //pagamento con nexi
            //aggiorno stato su QUOTASRC se non c'è disponibilità
            statusUpdate: function() {
                var self = this;

                var bodyFormData = new FormData();
                bodyFormData.append('quota_id', self.quota_id);
                bodyFormData.append('nrchoice', self.nrchoice);
                bodyFormData.append('validation_token', self.validation_token);
                this.$axios({
                    method: 'post',
                    url: '/booking/'+self.$company+'/statusUpdate',
                    data: bodyFormData
                }).then((response) => {
                    if(response.data == 1){
                        self.$store.dispatch('quotaLoadSendNoDisponibilityMail');
                        self.modal_message = `<strong>${this.getTranslation('QUOTA_NOT_AVAILABLE')}</strong>`
                    }else{
                        self.modal_message = `<strong>${this.getTranslation('QUOTA_TRY_AGAIN')}</strong>`
                    }
                    self.openModal();
                });
            },
            controlServices() {
                if (this.additional_services == null){
                    return false
                }else if(!this.additional_services[this.nrchoice]) {
                    return false
                }else{
                    return true
                }
            }
        }
    }
</script>