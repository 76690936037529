<template>
	<div id="search-results">
		<loader v-if="check_in && !search_results"></loader>
		<div class="search_results_body" v-if="search_results.length > 0">
			<div v-for="search_result in search_results" v-bind:key="search_result.ID">
				<SearchResultPackage v-if="search_result.TYPE == 'package'" v-bind:result_data="search_result"></SearchResultPackage>
				<SearchResult v-else v-bind:result_data="search_result"></SearchResult>
			</div>
		</div>
		<div class="search_results_other_availabilities" v-if="search_results.length == 0">
			<div class="search_results_other_availabilities_box">
				<div v-if="minimum_stay != ''">
					<SearchResultsMoreNightsAvailability v-bind:nights="minimum_stay"></SearchResultsMoreNightsAvailability>
				</div>
				<div v-else>
					<h3>{{ getTranslation('NO_AVAILABILITY_CURRENT_SEARCH') }}</h3>
					<h4>{{ getTranslation('NO_AVAILABILITY_CURRENT_SEARCH_2') }} <a :href="'mailto:'+company_email">{{ company_email }}</a></h4>
					<br v-if="other_availabilities.length > 0"/>
					<h4 v-if="other_availabilities.length > 0">{{ getTranslation('NEAR_AVAILABILITIES') }}</h4>
					<SearchResultsOtherAvailability v-for="other_availability in other_availabilities" v-bind:key="other_availability.DATE" v-bind:availability_row="other_availability"></SearchResultsOtherAvailability>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchResult from './SearchResult.vue'
import SearchResultPackage from './SearchResultPackage.vue'
import SearchResultsMoreNightsAvailability from './SearchResultsMoreNightsAvailability.vue'
import SearchResultsOtherAvailability from './SearchResultsOtherAvailability.vue'

export default {
	name: 'SearchResults',
	components: {
        SearchResult,
        SearchResultPackage,
        SearchResultsMoreNightsAvailability,
        SearchResultsOtherAvailability
    },
    computed: {
		company_email: function() {
			return this.$store.state.company_info.email;
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		search_results: function() {
			let results = this.$store.state.results;
			if(this.$store.state.current_room > 0) {
				for(let i = 0; i < results.length; i++) {
					for(let j = 0; j < this.$store.state.rooms.length; j++) {
						if(results[i].ID == this.$store.state.rooms[j].room_selected.ID) {
							results[i].QTY = results[i].QTY - 1;
						}
						if(results[i].ROOMS){
							results[i].ROOMS.forEach(room => {
								if(room.ID == this.$store.state.rooms[j].room_selected.ID){
									room.QTY = room.QTY - 1;
								}
							});
						}
					}
					
					let selectedPolicyID = this.$store.state.rooms[0].policy_selected.POLICY_ID;
					if(results[i].POLICIES){
						results[i].POLICIES = results[i].POLICIES.filter(policy => policy.POLICY_ID == selectedPolicyID);
					}
					else {
						results[i].ROOMS.forEach(room => {
							room.POLICIES = room.POLICIES.filter(policy => policy.POLICY_ID == selectedPolicyID);
						});
					}
				}

				let i = results.length
				while (i--) {
					if (
						results[i].QTY <= 0 || 
						(results[i].POLICIES && results[i].POLICIES.length <= 0) || 
						(results[i].ROOMS && results[i].ROOMS.length > 0 && results[i].ROOMS.every(room => room.POLICIES && room.POLICIES.length <= 0 || room.QTY <= 0)) 
					) { 
						results = results.filter((_, index) => index !== i);
					}
				}
			}
			return results;
		},
		minimum_stay: function() {
			return this.$store.state.minimum_stay;
		},
		other_availabilities: function() {
			return this.$store.state.other_availabilities;
		}
	},
	methods: {
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		}
	}
}
</script>