<template>
    <div class="quota_load">
        <div v-if="hasrows"> <!-- verificare se preventivo esiste -->
            <div class="quota_load_logo"><a :href="website" target="_blank"><img :src="logo" /></a></div>
            <!-- DESCRIZIONE GENERALE -->
            <div class="container" v-if="status_check == 1">
                <h1>{{ getTranslation('QUOTA_OFFER') }}</h1>
                <p>{{ getTranslation('QUOTA_VALIDITY') }} <b>{{ expiration }}</b><br>
                    {{ getTranslation('QUOTA_RITARDATI') }}<br><br>
                    {{ getTranslation('QUOTA_GENTLE') }} <b>{{ quotas[nrchoice][0].REFERENCE }}</b>
                </p>
                <p v-html="transformString(getTranslation('QUOTA_DES'))"></p>
            </div>
            <div class="container" v-else-if="status_check == 2">
                <h1>{{ getTranslation('QUOTA_CONFIRMED') }}</h1>
                <p>{{ getTranslation('QUOTA_GENTLE') }} <b>{{ quotas[nrchoice][0].REFERENCE }}</b><br>
                </p>
                <p v-html="getTranslation('QUOTA_CONFIRM_DES')"></p>
                <br>
            </div>
            <div class="container" v-else-if="status_check == 3">                
                <p>{{ getTranslation('QUOTA_GENTLE') }} <b>{{ quotas[nrchoice][0].REFERENCE }}</b><br>
                </p>
                <p>{{ getTranslation('BANK_TRANSFER_REQUEST') }}</p>
                <p>{{ getTranslation('QUOTA_TOMADE') }} <b>{{ $formatDateIt(quotas[nrchoice][0].EXPIRATION) }}</b></p>
                <p>IBAN: <b>{{ quotas[nrchoice][0].IBAN }}</b></p>
                <p v-if="quotas[nrchoice][0].BANCA != ''">{{ getTranslation('BANK') }}: <b>{{ quotas[nrchoice][0].BANCA }}</b></p>
                <br>
            </div>
            <div class="quota_load_navtab" v-if="Object.values(quotas).length > 1">
                <button 
                    class="quota_load_button_nav" 
                    v-for="(quotas, key) in quotas" 
                    :key="key"  
                    @click="selectNrchoice(key)"
                    :class="{active: selectedQuota == key}"
                    :disabled="allowedKey !== null && key != allowedKey"
                >
                    {{ getTranslation('QUOTA_PROPOSAL') }}{{ key }}
                </button>
            </div>
            
            <Quotas
                :isPastDate = isPastDate(expiration)
                :validation_token = validation_token
                :status_check = status_check
            ></Quotas>

            <!-- bottone per scaricare il resoconto della prenotazione -->
            <!-- <div  v-if="status_check != 1" class="export_pdf_button" @click="exportToPDF">{{ getTranslation('QUOTA_REPORT') }}</div> -->
        </div>
        <div v-if="!hasrows">
            <div class="logo"><a>{{ getTranslation('QUOTA_NOT_FOUND') }}</a></div>
        </div>
    </div>
</template>

<script>
import Quotas from './Quotas.vue';
import html2pdf from "html2pdf.js";

export default {
    name: 'QuotaLoad',

    components: {
        Quotas
    },

    data: function () {

        return {
            hasrows: '',
            expiration: '',
            selectedQuota: 1,
            check_status: '',
            allowedKey: null
        }
    },

    computed: {
        logo: function() {
			return encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/company/' + this.$store.state.company_info.logo);
		},
        website: function() {
			return 'http://' + this.$store.state.company_info.website;
		},
        ages: function() {
            return this.$store.state.ages;
        },
        quotas: function() {
            return this.$store.state.quotas;
        },
        quota: function() {
            return this.$store.state.quotas[1];
        },
        nrchoice: function() {
            return this.$store.state.quota.nrchoice;
        },
        total_lodge: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_total_lodge[nrchoice];
        },
        total_guest: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_total_guest[nrchoice];
        },
        checkin: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_checkin[nrchoice];
        },
        checkout: function() {
            var nrchoice = this.nrchoice;
            return this.$store.state.quotas_checkout[nrchoice];
        },
        status_check: function() {
            
            if (this.check_status == null){
                return 1; //non confermato
            }else{
                
                if(this.check_status.QUOTAS_STATUS == 2 || this.check_status.SRC_STATUS == 51){
                    var self = this;
                    self.selectedQuota = this.check_status.NRCHOICE;
                    self.selectNrchoice(this.check_status.NRCHOICE);
                    self.allowedKey = this.check_status.NRCHOICE;                    

                    if(this.check_status.PAYINFO == 'BONIFICO'){
                        return 3 //attesa di bonifico
                    }

                    return 2; //preventivo confermato
                }else{
                    return 1;
                }
            }
        }
	},

    methods: {  
        getTranslation: function(key) {
            return this.$store.getters.get_translation(key);
        },
        selectNrchoice: function(key) {
            this.$store.commit('setQuotaNrchoice', key);
            this.selectedQuota = key;
        },
        setCodeLang: function() {
            return String(this.$store.getters.get_lang_code);
        },
        // Funzione per convertire 'dd/mm/yyyy' in un oggetto Date
        parseDate(dateStr) {
            const [day, month, year] = dateStr.split('-');
            return new Date(`${year}-${month}-${day}`);
        },
        // Funzione per verificare se la data è passata
        isPastDate(dateStr) {
            const date = this.parseDate(dateStr);
            const today = new Date();
            // Ignoriamo l'ora confrontando solo la parte della data
            return date < today.setHours(0, 0, 0, 0);
        },
        transformString(stringa) {
            return stringa.replace('#', this.getTranslation('QUOTA_ACCEPT'));
        },
        formatPrice(price) {
            let number = parseFloat(price);
            return number.toFixed(2).replace('.', ',');
        },
        exportToPDF() {
            let doc_width = 8.27;  // A4 measures 210 × 297 millimeters or 8.27 × 11.69 inches
			let doc_height = 11.69;
			let dpi = 88; // targeting ~1200 window width
			let img_width = doc_width * dpi;
			//let img_height = doc_height * dpi;
			let win_width = img_width;
			//let win_height = img_height;

			let html2canvasOpts = {
				scale: img_width/win_width,   // equals 1
				width: img_width,
				//height: img_height,
				windowWidth: win_width,
				//windowHeight: win_height,
			};

			let jsPDFOpts = {
				orientation: 'portrait',
				unit: 'in',
				format: [doc_width, doc_height]
			};

			html2pdf().set({
				margin: [0.4, 0.4, 0.4, 0.4],
				filename: 'quote_confirmation_' + this.quota[0].NUMBER + '_' + this.quota[0].YEAR + '.pdf',
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: html2canvasOpts,
				jsPDF: jsPDFOpts,
				pagebreak: { mode: ['css'] }
			}).from(this.createConfirmationHtml()).save();
        },
        
        createConfirmationHtml() {

            let totalPrice = this.formatPrice(this.$store.state.quotas_total_price[this.nrchoice])
            let acconto = this.formatPrice(this.$store.state.quotas_amount[this.nrchoice])
            let html = `<div style="font-size: 14px;">`;
            html += `<div style="font-size: 16px;"><b>`+this.getTranslation('QUOTA_CONFIRMED')+` - `+this.quota[0].NUMBER+`/`+this.quota[0].YEAR+`</b></div>`;
            html += `<br/><hr/><br/>`;

            html += `<div><b>`+this.$store.state.company_info.name+`</b></div>`;
            html += `<div>`+this.$store.state.company_info.address1+` - `+this.$store.state.company_info.address2+` `+this.$store.state.company_info.city+` (`+this.$store.state.company_info.province+`)</div>`;
            html += `<div>`+this.$store.state.company_info.email;
            if(this.$store.state.company_info.phones != '') {
                html += ` - `+this.$store.state.company_info.phones;
            }
            html += `</div><br/><hr/><br/>`;
            html += `<div><b>`+this.getTranslation('GUEST')+`:</b> `+this.quota[0].REFERENCE+` &nbsp; &nbsp;</div>`;
            html += `<div><b>`+this.getTranslation('EMAIL')+`:</b> `+this.quota[0].EMAIL+` &nbsp; &nbsp; <b>`+this.getTranslation('PHONE')+`:</b> `+this.quota[0].MOBILE+`</div>`;
            html += `<br/><hr/><br/>`;

            if(this.$store.state.quotas_total_lodge[this.nrchoice][0] != 0){

                for(let r = 0; r < this.quota.length; r++) {
                    let room = this.quota[r];
    
                    html += `<div style="font-size: 16px;"><b>`+room['NAME']+`</b></div>`;
                    html += `<div>`+room['DESCRIPTION']+`</div><br/>`;
    
                    html += `<div><b>`+this.getTranslation('ADULT')+`:</b> `+room.NRADULT;
                    if(this.ages.child1_age != null) {
                        html += ` &nbsp; <b>`+this.getTranslation('CHILD')+` <span style="font-size: 12px;">(`+this.ages.child1_age+`)</span>:</b> `+room.NRCHILD1;
                    }
                    if(this.ages.child2_age != null) {
                        html += ` &nbsp; <b>`+this.getTranslation('CHILD')+` <span style="font-size: 12px;">(`+this.ages.child2_age+`)</span>:</b> `+room.NRCHILD2;
                    }
                    if(this.ages.infant_age != null) {
                        html += ` &nbsp; <b>`+this.getTranslation('INFANT')+` <span style="font-size: 12px;">(`+this.ages.infant_age+`)</span>:</b> `+room.NRINFANT;
                    }
                    html += `</div><br/>`;
    
                    html += `<div style="font-size: 16px;"><b>`+room['ROOM_ARRANGEMENT']+`</b></div>`;
                    html += `<div><b>`+room['POLICY']+`</b></div>`;
                    html += `<div>`+room['ROOM_POLICY']+`</div><br/>`;
                    html += `<div style="text-align: right;"><b>`+room.TOTALPRICE+`€</b></div><br/>`;

                    html += `<hr/><br/>`;
                }
            }

            if(this.$store.state.quotas_services != null){
                if(this.$store.state.quotas_services[this.nrchoice] != null){

                    html += `<div style="font-size: 16px;"><b>`+this.getTranslation('QUOTA_ ADDITIONAL_SERVICES')+`</b></div> <br/>`;
        
                    for(let i = 0; i < this.$store.state.quotas_services[this.nrchoice].length; i++) {
                        
                        let selected_extra_charge = this.$store.state.quotas_services[this.nrchoice][i];

                        if(selected_extra_charge['spazio'] !=''){
                            html += `<div><span style="font-size: 12px;">`+selected_extra_charge['QTY']+` x</span> <b>`+selected_extra_charge['descrizione_spazio_'+this.setCodeLang()]+`:</b>  <b>`+selected_extra_charge['TOTALPRICE']+`€</b></div>`;
                        }
                        if(selected_extra_charge['prodotto_'+this.setCodeLang()] !=''){
                            html += `<div><span style="font-size: 12px;">`+selected_extra_charge['QTY']+` x</span> <b>`+selected_extra_charge['prodotto_'+this.setCodeLang()]+`:</b>  <b>`+selected_extra_charge['TOTALPRICE']+`€</b></div>`;
                        }
                        if(selected_extra_charge['trattamento_'+this.setCodeLang()] !=''){
                            html += `<div><span style="font-size: 12px;">`+selected_extra_charge['QTY']+` x</span> <b>`+selected_extra_charge['trattamento_'+this.setCodeLang()]+`:</b>  <b>`+selected_extra_charge['TOTALPRICE']+`€</b></div>`;
                        }
                        html += `<br/>`;
                    }
                    html += `<hr/><br/>`;
                }
            }

            html += `<div style="font-size: 20px; text-align: right;"><b>`+this.getTranslation('TOTALPRICE') + `: ` + totalPrice + `€</b></div>`;
            if (this.status_check == 3){
                html += `<div style="font-size: 12px; text-align: right;"><b>`+this.getTranslation('BANK_TRANSFER_REQUEST')+`</b></div>`;
                html += `<div style="font-size: 12px; text-align: right;"><b>IBAN: `+this.quota[0].IBAN+`</b></div>`;
                
            }
            html += `<div style="font-size: 16px; text-align: right;"><b>`+this.getTranslation('QUOTA_AMOUNT')+`: ` + acconto + `€</b></div>`;
            html += `</div>`;

            html += `<style>
                hr {
                    border: none;
                    height: 1px;
                    color: #333333;
                    background-color: #333333;
                }
            </style>`;

            return html;
        }
    },

    created: function () {

        let params = window.location.pathname.split('/').slice(1)
        this.quota_id = params[2]
        this.validation_token = params[3]
        this.preview = params[4]

        this.$store.commit('setQuotaId', this.quota_id);
        this.$axios({
            method: 'post',
            url: '/booking/'+this.$company+'/loadQuota/'+this.quota_id+'/'+this.validation_token,
        }).then(response =>{
            if(response.data){
                this.hasrows = response.data.hasrows;
                this.expiration = response.data.expiration;
                this.check_status = response.data.check_status;
                this.$store.commit('setCompanyID', response.data.company_id)
                this.$store.commit('setQuotas', response.data.quotas);
                this.$store.commit('setQuotasNewQty', response.data.newQty);
                this.$store.commit('setQuotasCheckin', response.data.checkin);
                this.$store.commit('setQuotasCheckout', response.data.checkout);
                this.$store.commit('setQuotasTotalguest', response.data.total_guest);
                this.$store.commit('setQuotasTotallodge', response.data.total_lodge);
                this.$store.commit('setQuotasTotalnights', response.data.total_nights);
                this.$store.commit('setQuotasTotal', response.data.total);
                this.$store.commit('setQuotasTotalCorrectionrate', response.data.total_correction);
                this.$store.commit('setQuotasTotalprice', response.data.total_price);
                this.$store.commit('setQuotasAmount', response.data.amount);
                this.$store.commit('setQuotasServices', response.data.quotas_services);
                this.$store.commit('setQuotasFirstdayprice', response.data.firstdayprice);
                this.$store.commit('setLang', response.data.lang);   
                this.$store.commit('setQuotascommonData', response.data.commonData);
                this.$store.commit('setQuotasdayDetail', response.data.dayDetail);
                this.$store.commit('setQuotasdays', response.data.quotasdays);   
                this.$store.commit('setQuotaspolicyDescription', response.data.policyDescription) 
                this.$store.commit('setQuotasEmailFooter', response.data.email_footer)

                for (let index in response.data.quotas) {
                    if(index === 1){
                        break
                    }else{
                        this.$store.commit('setQuotaNrchoice', index)
                        break
                    }
                }

                if(response.data.hasrows && this.preview !== 'preview'){
                    var self = this
                    var bodyFormData = new FormData();
                    bodyFormData.append('quota_id', self.quota_id);
                    bodyFormData.append('validation_token', self.validation_token);
                    this.$axios({
                        method: 'post',
                        url: '/booking/'+self.$company+'/onPageOpen',
                        data: bodyFormData
                    })
                }
            }
        });

        
    }, 
}
</script>