<template>
	<div id="checkout" class="checkout">
		<div class="checkout_payment_error" v-if="payment_error">{{ payment_error }}</div>
		<h2>{{ getTranslation('OVERVIEW') }}</h2>
		<div class="checkout_section desktop_hidden" style="margin-bottom: 10px;">
			<div class="summary_block_1_row">
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKIN') }}</span>
					{{ check_in_formatted }}
				</div>
				<div class="summary_block_1_col">
					<span>{{ getTranslation('CHECKOUT') }}</span>
					{{ check_out_formatted }}
				</div>
			</div>
		</div>
		<div v-if="booking_type == 2" class="checkout_section" style="border-left: none; padding: 0;">
			<div class="checkout_section_room">
                <img v-if="package_image()" :src="package_image()" alt="" class="checkout_section_room_image">
                <div class="checkout_section_room_description">
                    <div>
                        <h3>{{ package_name() }}</h3>
                        <div class="description_inline" @click.prevent="roomDescription(room_image1(), room_description())">
                            <h4>{{ room_name() }}</h4><i class="fas fa-info-circle"></i>
                        </div>
                        <div class="policy">
                            <span>
                                {{ room_policy() }} -
                            </span>
                            <a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup()" href="">
                                {{ getTranslation('QUOTA_POLICY') }}
                            </a>
                        </div>
                        <div class="nr_guests">
                            <span><i class="fas fa-user-friends"></i> {{ adults(0) }} {{ getTranslation('ADULT') }}</span>
                            <span v-if="child1(0) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child1(0) }}</b></span>
                            <span v-if="child2(0) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child2(0) }}</b></span>
                            <span v-if="infants(0) > 0"><i class="fas fa-baby"></i> {{ getTranslation('INFANT') }}: <b>{{ infants(0) }}</b></span>
                        </div>
                        <br>
                        <span v-html="package_description()"></span>
                    </div>
                    <div class="room_price">
                        <div class="room_price_inline">
                            <label style="color: black;">{{ room_price() }} €</label>
                        </div>
                        <span>{{ getTranslation('PRICEFOR') }} {{ nights }} {{ nights_text }}</span>
                    </div>
                </div>
            </div>
			<div class="checkout_section_extra_charges" v-if="selected_extra_charges(0).length > 0">
				<div class="checkout_section_extra_charges_row" v-for="extra_charge in selected_extra_charges(0)" v-bind:key="extra_charge.IDSERVICE">
					<div class="checkout_section_extra_charges_row_top">
						<h3 v-html="extra_charge.title"></h3>
						<span v-html="extra_charge.description"></span>
					</div>
					<div class="checkout_section_extra_charges_row_bottom">
						<span>{{ extra_charge.quantity_selected }} x <b>{{ extra_charge.import_formatted }} €</b></span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="booking_type == 1">
			<div class="checkout_section" style="border-left: none; padding: 0;" v-for="(room, index) in rooms" v-bind:key="index">
				<div class="checkout_section_room" v-if="room.package_selected == ''">
					<img v-if="room_image1(index)" :src="room_image1(index)" alt="" class="checkout_section_room_image" @click.prevent="roomDescription(room_image1(index), room_description(index))">
					<div class="checkout_section_room_description">
						<div> 
							<div class="description_inline" @click.prevent="roomDescription(room_image1(index), room_description(index))">
								<h3>{{ room_name(index) }}</h3><i class="fas fa-info-circle" style="color: var(--mainColor);"></i>
							</div>
							<div class="policy">
								<span>
									{{ room_policy(index) }} -
								</span>
								<a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup()" href="">
								{{ getTranslation('QUOTA_POLICY') }}
								</a>
							</div>
							<div class="arrangement">
								<i class="fas fa-concierge-bell"></i>
								<!-- <span>{{ quotas['ROOM_ARRANGEMENT_'+setCodeLang] }}</span> -->
								<span v-html="room_arrangement(index)"></span>
							</div>
							<div class="nr_guests">
								<span><i class="fas fa-user-friends"></i> {{ adults(index) }} {{ getTranslation('ADULT') }}</span>
								<span v-if="child1(index) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child1(index) }}</b></span>
								<span v-if="child2(index) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child2(index) }}</b></span>
								<span v-if="infants(index) > 0"><i class="fas fa-baby"></i> {{ getTranslation('INFANT') }}: <b>{{ infants(index) }}</b></span>
							</div>
						</div>
						<div class="room_price">
							<div class="room_price_inline">
								<label style="color: black;">{{ room_price(index) }} €</label>
							</div>
							<span>{{ getTranslation('PRICEFOR') }} {{ nights }} {{ nights_text }}</span>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="checkout_section_room">
						<img v-if="package_image(index)" :src="package_image(index)" alt="" class="checkout_section_room_image" @click.prevent="roomDescription(package_image(index), package_description(index))">
						<div class="checkout_section_room_description">
							<div> 
								<h3>{{ package_name(index) }}</h3>
								<div class="description_inline" @click.prevent="roomDescription(room_image1(index), room_description(index))">
									<h4>{{ room_name(index) }}</h4><i class="fas fa-info-circle"></i>
								</div>
								<div class="policy">
									<span>
										{{ room_policy(index) }} -
									</span>
									<a class="secondario" style="cursor: pointer;" @click.prevent="termsPopup()" href="">
										{{ getTranslation('QUOTA_POLICY') }}
									</a>
								</div>
								<div class="nr_guests">
									<span><i class="fas fa-user-friends"></i> {{ adults(0) }} {{ getTranslation('ADULT') }}</span>
									<span v-if="child1(0) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child1(0) }}</b></span>
									<span v-if="child2(0) > 0"><i class="fas fa-child"></i> {{ getTranslation('CHILD') }}: <b>{{ child2(0) }}</b></span>
									<span v-if="infants(0) > 0"><i class="fas fa-baby"></i> {{ getTranslation('INFANT') }}: <b>{{ infants(0) }}</b></span>
								</div>
								<br>
								<div class="description_inline" style="flex-direction: column;">
									<p v-html="package_description(index)"></p>
								</div>
							</div>
							<div class="room_price">
								<div class="room_price_inline">
									<label style="color: black;">{{ room_price(index) }} €</label>
								</div>
								<span>{{ getTranslation('PRICEFOR') }} {{ nights }} {{ nights_text }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="checkout_section_extra_charges" v-if="selected_extra_charges(index).length > 0" style="padding: 15px;">
					<div class="checkout_section_extra_charges_row" v-for="extra_charge in selected_extra_charges(index)" v-bind:key="extra_charge.IDSERVICE">
						<div class="checkout_section_extra_charges_row_top">
							<h3 v-html="extra_charge.title"></h3>
							<span v-html="extra_charge.description"></span>
						</div>
						<div class="checkout_section_extra_charges_row_bottom">
							<span>{{ extra_charge.quantity_selected }} x <b>{{ extra_charge.import_formatted }} €</b></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="checkout_section" v-if="booking_type == 1 && coupon.status == 1" style="margin-bottom: 10px;">
			<div class="checkout_section_discount">
				<div class="checkout_section_discount_left">
					<span>{{ getTranslation('DISCOUNT_APPLIED') }}</span>
					<span><b>{{ coupon.data.DESCRIPTION }}</b> (-{{ parseInt(coupon.data.DISCOUNT) }}%) &nbsp; &nbsp; <span @click="resetCouponCode()"><i class="fas fa-times-circle"></i> Rimuovi</span></span>
				</div>
				<div class="checkout_section_discount_right">
					{{ discount() }} €
				</div>
			</div>
		</div>
		<div class="checkout_section">
			<div class="thank_you_section_total">
				<div>
					{{ getTranslation('TOTALPRICE') }}
				</div>
				<div>
					{{ total() }} €
				</div>
			</div>
		</div>
		<div class="checkout_section" v-if="booking_type == 1 && coupon.status != 1">
			<div class="checkout_section_coupon_code">
				<div class="checkout_section_coupon_code_left">
					{{ getTranslation('COUPON_CODE') }}
				</div>
				<div class="checkout_section_coupon_code_right">
					<div class="coupon_code_row">
						<input type="text" v-model="coupon_code" :placeholder="[[getTranslation('COUPON_CODE_PLACEHOLDER')]]" @focus="resetCouponCode()" />
						<div class="coupon_code_button" @click="applyCouponCode()">{{ getTranslation('APPLY_COUPON_CODE') }}</div>
					</div>
					<div class="coupon_code_error" v-if="coupon.status == -1">
						<i class="fas fa-exclamation-triangle"></i> {{ getTranslation('COUPON_CODE_ERROR') }}
					</div>
				</div>
			</div>
		</div>
		<h2>{{ getTranslation('PERSONALDATA') }}</h2>
		<div class="checkout_section">
			<div class="checkout_section_row">
				<div class="checkout_section_col">
					<label for="first_name">{{ getTranslation('NAME') }} <span class="error"></span></label>
					<input type="text" id="first_name" name="first_name" v-model="first_name" />
				</div>
				<div class="checkout_section_col">
					<label for="last_name">{{ getTranslation('SURNAME') }} <span class="error"></span></label>
					<input type="text" id="last_name" name="last_name" v-model="last_name" />
				</div>
			</div>
			<div class="checkout_section_row">
				<div class="checkout_section_col">
					<label for="email">{{ getTranslation('EMAIL') }} <span class="error"></span></label>
					<input type="email" id="email" name="email" v-model="email" />
				</div>
				<div class="checkout_section_col">
					<label for="email_confirm">{{ getTranslation('CONFIRMEMAIL') }} <span class="error"></span></label>
					<input type="email" id="email_confirm" name="email_confirm" v-model="email_confirm" />
				</div>
			</div>
			<div class="checkout_section_row">
				<div class="checkout_section_col">
					<label for="tel">{{ getTranslation('PHONE') }} <span class="error"></span></label>
					<input type="tel" id="tel" name="tel" v-model="tel" />
				</div>
				<div class="checkout_section_col">
					<label for="tel">{{ getTranslation('STATE') }}</label>
					<select name="country" id="country" v-model="country">
						<option v-for="country in countries" v-bind:key="country.ID" v-bind:value="country.ID">{{ country.DESCRIPTION }}</option>
					</select>
				</div>
			</div>
		</div>
		<h2>{{ getTranslation('INFORMATIONS') }}</h2>
		<div class="checkout_section">
			<div class="checkout_section_row">
				<div class="checkout_section_col">
					<label for="notes">{{ getTranslation('REQUESTS') }}</label>
					<textarea id="notes" name="notes" rows="4" v-model="notes"></textarea>
				</div>
			</div>
			<div class="checkout_section_row">
				<div class="checkout_section_col checkboxes">
					<input id="privacy_policy" type="checkbox" value="1" v-model="privacy_policy">
					<label for="privacy_policy"><a href="#" @click.prevent="privacyPopup()">{{ getTranslation('PRIVACY') }}</a> &nbsp; <span class="error"></span></label>
				</div>
			</div>
			<div class="checkout_section_row">
				<div class="checkout_section_col checkboxes">
					<input id="terms" type="checkbox" value="1" v-model="terms">
					<label for="terms"><a href="#" @click.prevent="termsPopup()">{{ getTranslation('POLICYTEXT') }}</a> &nbsp; <span class="error"></span></label>
				</div>
			</div>
		</div>
		<!-- metodi di pagamento -->

		<h2 v-if="!payment_methods.nexi && check_bonifico == 0">{{ getTranslation('CREDITCARDINFO') }}</h2>
		<h2 v-if="!payment_methods.nexi && check_bonifico == 1">{{ getTranslation('PAYMENT_METHOD') }}</h2>
		<div class="checkout_section" v-if="!payment_methods.nexi">
			<div v-if="check_bonifico == 1" class="checkout_section_row payment_method" >
				<div style="display: flex; gap: 5px; margin-right: 15px;">
					<input type="radio" id="payment_method" v-model="payment_method" value="2" @click="assignPaymentMethod(2)"  style="accent-color: var(--mainColor);">
					<label for="payment_method">CARTE</label>
				</div>
				<div style="display: flex; gap: 5px; margin-right: 15px;">
					<input type="radio" id="payment_method" v-model="payment_method" value="1" @click="assignPaymentMethod(1)" style="accent-color: var(--mainColor);">
					<label for="payment_method">BONIFICO</label>
				</div>
			</div>
			<div v-if="paymentMethod == 2 || check_bonifico == 0">
				<div class="checkout_section_row">
					<div class="checkout_section_col">
						<label for="cc_type">{{ getTranslation('CRCTYPE') }} <span class="error"></span></label>
						<select id="cc_type" name="cc_type" v-model="cc_type">
							<option v-for="payment_method in payment_methods" v-bind:key="payment_method.ID" v-bind:value="payment_method.ID">{{ payment_method.DESCRIPTION }}</option>
						</select>
					</div>
					<div class="checkout_section_col" v-if="cc_type_nickname != '' && (cc_type_nickname != 'SCALAPAY' && cc_type_nickname != 'NEXI PAY')">
						<label for="cc_number">{{ getTranslation('CRCNUM') }} <span class="error"></span></label>
						<input type="text" id="cc_number" name="cc_number" v-model="cc_number" />
					</div>
					<div class="checkout_section_col" v-else></div>
				</div>
				<div class="checkout_section_row" v-if="cc_type_nickname != '' && (cc_type_nickname != 'SCALAPAY' && cc_type_nickname != 'NEXI PAY')">
					<div class="checkout_section_col">
						<label for="cc_name">{{ getTranslation('CRCOWNER') }} <span class="error"></span></label>
						<input type="text" id="cc_name" name="cc_name" v-model="cc_name" />
					</div>
					<div class="checkout_section_col">
						<div class="checkout_section_row_special">
							<div class="checkout_section_col_special">
								<label for="cc_expire">{{ getTranslation('CRCSCA') }} <span class="error"></span></label>
								<div>
									<select id="cc_expire_month" name="cc_expire_month" v-model="cc_expire_month">
										<option value="01">01</option>
										<option value="02">02</option>
										<option value="03">03</option>
										<option value="04">04</option>
										<option value="05">05</option>
										<option value="06">06</option>
										<option value="07">07</option>
										<option value="08">08</option>
										<option value="09">09</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
									</select>&nbsp; / &nbsp;
									<select id="cc_expire_year" name="cc_expire_year" v-model="cc_expire_year">
										<option v-for="expire_year in expire_years" v-bind:key="expire_year" v-bind:value="expire_year">{{ expire_year }}</option>
									</select>
								</div>
							</div>
							<div class="checkout_section_col_special CVV">
								<label for="cc_cvv">CVV <span class="error"></span></label>
								<input type="password" id="cc_cvv" name="cc_cvv" v-model="cc_cvv" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="quota_load_section" style ="box-shadow:none; padding-left: 0px; padding-top: 0px;" v-else-if="paymentMethod == 1">
				<div> 
					<h3 style="margin-bottom: 10px;">{{ getTranslation('BANK_TRANSFER_REQUEST') }}:</h3>
					<div style="margin-bottom: 5px;">IBAN : {{ iban }}</div>
					<div style="margin-bottom: 5px;">{{ getTranslation('BANK') }} : {{ banca }}</div>
					<div>{{ getTranslation('QUOTA_AMOUNT') }}:  {{ bonifico_amount }} €</div>
				</div>
			</div>
		</div>
		<div class="checkout_button_container">
			<div class="checkout_button" v-if="cc_type_nickname != 'SCALAPAY' && cc_type_nickname != 'NEXI PAY'" @click="confirmPrenotation()">{{ getTranslation('CONFIRMPRENOTATION') }}</div>
			<div class="checkout_button" v-else-if="cc_type_nickname == 'NEXI PAY'" @click="goToPayment()">{{ getTranslation('PROCEED_TO_NEXI') }}</div>
			<div class="checkout_button" v-else @click="goToPayment()">{{ getTranslation('PROCEED_TO_PAYMENT') }}</div>
		</div>
		<div class="desktop_hidden">
			<hr/>
			<div class="summary_block_reset" @click="backToSearch()">
				{{ getTranslation('NEWSEARCH') }}
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery'
let $ = jQuery

export default {
	name: 'Checkout',
	data() {
		return {
			countries: '',
			payment_methods: '',
			check_bonifico: '', //0 bonifico NON attivo, 1 bonifico attivo
			bonifico_amount: '', //acconto da versare con il bonifico
			first_name: '',
			last_name: '',
			email: '',
			email_confirm: '',
			tel: '',
			country: 1,
			notes: '',
			privacy_policy: false,
			terms: false,
			payment_method: '',
			cc_type: '',
			cc_number: '',
			cc_name: '',
			cc_expire_month: '',
			cc_expire_year: '',
			cc_cvv: '',
			coupon_code: ''
		}
	},
	computed: {
		paymentMethod: function() {
			return this.$store.state.payment_bonifico;
		},
		iban: function() {
			return this.$store.state.company_info.iban;
		},
		banca: function() {
			return this.$store.state.company_info.banca;
		},
		lang: function() {
			return this.$store.state.lang;
		},
		ages: function() {
			return this.$store.state.ages;
		},
		check_in: function() {
			return this.$store.state.last_search.check_in;
		},
		check_in_formatted: function() {
			return this.$formatDateIt(this.check_in.replace(/-/g, '/'));
		},
		check_out: function() {
			return this.$store.state.last_search.check_out;
		},
		check_out_formatted: function() {
			return this.$formatDateIt(this.check_out.replace(/-/g, '/'));
		},
		booking_type: function() {
			return this.$store.state.booking_type;
		},
		rooms: function() {
			return this.$store.state.rooms;
		},
		nights: function() {
			return this.$calculateNights(new Date(this.check_in), new Date(this.check_out));
		},
		nights_text: function() {
			if(this.nights == 1) {
				return this.getTranslation('NIGHT');
			} else {
				return this.getTranslation('NIGHTS');
			}
		},
		expire_years: function() {
			let year = new Date().getFullYear();
			let years = [];
			for(let i = 0; i <= 10; i++) {
				years.push(year);
				year++;
			}

			return years;
		},
		country_code: function() {
			let country_code = '';
			for(let index = 0; index < this.countries.length; index++) {
				if(this.countries[index].ID == this.country) {
					country_code = this.countries[index].ALPHA2CODE;
				}
			}
			return country_code;
		},
		payment_error: function() {
			return this.$store.state.payment_error;
		},
		cc_type_nickname: function() {
			var self = this
			if(self.cc_type == 'NEXI PAY') {
				return 'NEXI PAY';
			}

			for (const [key, value] of Object.entries(this.payment_methods)) { // eslint-disable-line no-unused-vars
				if(value.ID == self.cc_type) {
					return value.NICKNAME;
				}
			}
			
			return '';
		},
		coupon: function() {
			return this.$store.state.coupon;
		}
	},
	created: function () {
		var self = this;

		this.$axios({
			method: 'get',
			url: '/booking/'+this.$company+'/getCountries/'+this.lang
		}).then(function (response) {
			self.countries = response.data;
		});

		this.$axios({
			method: 'get',
			url: '/booking/'+this.$company+'/getPaymentMethods/'+self.$store.state.company_id +'/booking',
		}).then(function (response) {
			self.payment_methods = response.data;
			if(self.payment_methods.nexi) {
				self.cc_type = 'NEXI PAY';
			}
		});

		//Andrea
		//parte di codice aggiunta per vedere se c'è BONIFICO come opzione di pagamento
		var bodyFormData = new FormData();
		if(this.$store.state.booking_type == 1){
			bodyFormData.append('policy_id', this.$store.state.rooms[0].policy_selected.POLICY_ID);
		}else if(this.$store.state.booking_type == 2){
			bodyFormData.append('policy_id', this.$store.state.package.package_room_selected.POLICY.POLICY_ID);
		}
		bodyFormData.append('check_in', this.$store.state.check_in);
		bodyFormData.append('check_out', this.$store.state.check_out);
		bodyFormData.append('total', this.total_not_formatted());

		this.$axios({
			method: 'post',
			url: '/booking/'+this.$company+'/checkBonifico',
			data: bodyFormData
		}).then(function (response) {
			if(response.data.bonifico_bol == 0 || response.data.bonifico_bol == null){
				self.check_bonifico = 0
				self.bonifico_amount = response.data.amount
			} else {
				self.check_bonifico = response.data.bonifico_bol
				self.bonifico_amount = response.data.amount
			}

			self.$store.commit('setAmountBonifico', self.bonifico_amount);
		});

		if(this.$store.state.tracking_codes['TRACKING_SCRIPT_CHECKOUT'] != undefined) {
			let tracking_code = this.$store.state.tracking_codes['TRACKING_SCRIPT_CHECKOUT'];

			if(this.booking_type == 1) {
				let travelers  = 0;
				let price = 0;
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.rooms[index].policy_selected.TOTAL_PRICE != undefined) {
						travelers += this.rooms[index].adults + this.rooms[index].child1 + this.rooms[index].child2 + this.rooms[index].infants;
						price += this.rooms[index].policy_selected.TOTAL_PRICE;
					}
				}

				let gtag_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.room_ID(index) != undefined) {
						gtag_items += `
							{
								"id": `+this.room_ID(index)+`,
								"name": "`+this.room_name(index)+`",
								"quantity": 1,
								"price": "`+this.room_price_not_formatted(index).toFixed(2)+`"
							},
						`;
					}
				}
				tracking_code = tracking_code.replace(/%gtagitems%/g, gtag_items);

				let piwik_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					if(this.room_ID(index) != undefined) {
						piwik_items += `
							{
								sku: '`+this.room_ID(index)+`',
								name: '`+this.room_name(index)+`',
								price: `+this.room_price_not_formatted(index).toFixed(2)+`,
								quantity: 1,
							},
						`;
					}
				}
				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, price.toFixed(2));

				tracking_code = tracking_code.replace(/%ci/g, this.check_in);
				tracking_code = tracking_code.replace(/%co/g, this.check_out);
				tracking_code = tracking_code.replace(/%rn/g, '');
				tracking_code = tracking_code.replace(/%r/g, this.rooms.length);
				tracking_code = tracking_code.replace(/%t/g, travelers);
				tracking_code = tracking_code.replace(/%p/g, price.toFixed(2));
			} else if(this.booking_type == 2) {
				let gtag_items = `
					{
						"id": `+this.room_ID(0)+`,
						"name": "`+this.room_name(0)+`",
						"quantity": 1,
						"price": "`+this.room_price_not_formatted(0).toFixed(2)+`"
					}
				`;
				tracking_code = tracking_code.replace(/%gtagitems%/g, gtag_items);

				let piwik_items = '';
				for(let index = 0; index < this.rooms.length; index++) {
					piwik_items += `
						{
							sku: '`+this.room_ID(0)+`',
							name: '`+this.room_name(0)+`',
							price: `+this.room_price_not_formatted(0).toFixed(2)+`,
							quantity: 1,
						},
					`;
				}

				tracking_code = tracking_code.replace(/%piwikitems%/g, piwik_items);

				tracking_code = tracking_code.replace(/%total%/g, this.room_price_not_formatted(0).toFixed(2));

				tracking_code = tracking_code.replace(/%ci/g, this.check_in);
				tracking_code = tracking_code.replace(/%co/g, this.check_out);
				tracking_code = tracking_code.replace(/%rn/g, this.room_name(0));
				tracking_code = tracking_code.replace(/%r/g, '1');
				tracking_code = tracking_code.replace(/%t/g, this.adults(0) + this.child1(0) + this.child2(0) + this.infants(0));
				tracking_code = tracking_code.replace(/%p/g, this.room_price_not_formatted(0).toFixed(2));
			}

			eval(tracking_code);
		}
	},
	mounted: function() {
		$('input').focus(function() {
			$(this).removeClass('invalid_input');
			$(this).siblings('label').find('.error').html('');
		});
		$('select').focus(function() {
			$(this).removeClass('invalid_input');
			$(this).siblings('label').find('.error').html('');
			if($(this).attr('id') == 'cc_expire_month' || $(this).attr('id') == 'cc_expire_year') {
				$('label[for=cc_expire]').find('.error').html('');
			}
		});
	},
	methods: {
		assignPaymentMethod: function(method) {
			this.$store.commit('setPaymentBonifico', method);
			if(method == 1){
				this.cc_type = ''
				this.cc_number = ''
				this.cc_name = ''
				this.cc_expire_month = ''
				this.cc_expire_year = ''
				this.cc_cvv = ''
			}
		},
		roomDescription: function (image, room_desc) {
			const imageTag = image
				? `<img src="${image}" alt="camera" class="popup_image">`
				: '';

			const description = `
				<div class="privacy_popup" id="room_popup">
					${imageTag}
					<div>
						${room_desc}
					</div>
				</div>
			`;

			window.$(description).appendTo('body').modal();
		},
		getTranslation: function(key) {
			return this.$store.getters.get_translation(key);
		},
		adults: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].adults;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.adults;
			}
		},
		child1: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].child1;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.child1;
			}
		},
		child2: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].child2;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.child2;
			}
		},
		infants: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].infants;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.infants;
			}
		},
		package_name: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].package_selected['PACKAGE_NAME_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_selected['NAME_'+this.$store.getters.get_lang_code];
			}
		},
		package_image: function(index = 0) {
			let image;
			if(this.booking_type == 1) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.rooms[index].package_selected['IDCOMPANY']+'/packages/' + this.$store.state.rooms[index].package_selected['PACKAGE_ID'] + '/' + this.$store.state.rooms[index].package_selected['PACKAGE_IMAGE']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else if(this.booking_type == 2) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+ this.$store.state.package.package_selected.IDCOMPANY +'/packages/' + this.$store.state.package.package_selected.ID + '/' + this.$store.state.package.package_selected.IMAGE);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			}
		},
		package_description: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].package_selected['PACKAGE_DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
		},
		room_ID: function(index) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['ID'];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['ID'];
			}
			return 0;
		},
		room_name: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['NAME_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['NAME_'+this.$store.getters.get_lang_code];
			}
			return '';
		},
		room_image1: function(index = 0) {
			let image;
			if(this.booking_type == 1) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE1']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			} else if(this.booking_type == 2) {
				image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE1']);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");
				return image;
			}
			return '';
		},
		room_image2: function(index = 0) {
			let image;
			if(this.booking_type == 1) {
				if(this.$store.state.rooms[index].room_selected['IMAGE2'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE2']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			} else if(this.booking_type == 2) {
				if(this.$store.state.package.package_room_selected['IMAGE2'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE2']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			}
			return false;
		},
		room_image3: function(index = 0) {
			let image;
			if(this.booking_type == 1) {
				if(this.$store.state.rooms[index].room_selected['IMAGE3'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.rooms[index].room_selected['ID'] + '/' + this.$store.state.rooms[index].room_selected['IMAGE3']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			} else if(this.booking_type == 2) {
				if(this.$store.state.package.package_room_selected['IMAGE3'] != '') {
					image = encodeURI(this.$baseURL + '/structures/'+this.$company+'/'+this.$store.state.company_id+'/rooms/' + this.$store.state.package.package_room_selected['ID'] + '/' + this.$store.state.package.package_room_selected['IMAGE3']);
					image = image.replace("(", "%28");
					image = image.replace(")", "%29");
					return image;
				} else {
					return false;
				}
			}
			return false;
		},
		room_description: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected['DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
			return '';
		},
		room_arrangement: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['ARRANGEMENT_NAME_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_DESCRIPTIONSERVICES_'+this.$store.getters.get_lang_code];
			}
		},
		room_policy: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['POLICY_NAME_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_NAME_'+this.$store.getters.get_lang_code];
			}
		},
		room_policy_description: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			} else {
				return this.$store.state.package.package_selected['POLICY_DESCRIPTION_'+this.$store.getters.get_lang_code];
			}
		},
		room_price_not_formatted: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE;
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected.TOTAL_PRICE;
			}
			return '';
		},
		room_price: function(index = 0) {
			if(this.booking_type == 1) {
				return this.$store.state.rooms[index].policy_selected.TOTAL_PRICE.toFixed(2).replace(".", ",");
			} else if(this.booking_type == 2) {
				return this.$store.state.package.package_room_selected.TOTAL_PRICE.toFixed(2).replace(".", ",");
			}
			return '';
		},
		selected_extra_charges: function(index = 0) {
			let extra_charges = [];
			let self = this;

			let extra_charges_arr = [];
			if(this.booking_type == 1) {
				extra_charges_arr = this.$store.getters.selected_extra_charges(index);
			} else if(this.booking_type == 2) {
				extra_charges_arr = this.$store.getters.selected_package_extra_charges;
			}

			extra_charges_arr.forEach(function(extra_charge) {
				let has_image = false;
				if(extra_charge.IMAGE != '') {
					has_image = true;
				}
				let image = encodeURI(self.$baseURL + '/structures/'+self.$company+'/'+self.$store.state.company_id+'/services/' + extra_charge.IDSERVICE + '/' + extra_charge.IMAGE);
				image = image.replace("(", "%28");
				image = image.replace(")", "%29");

				let title = extra_charge['NAME_'+self.$store.getters.get_lang_code];
				let description = extra_charge['DESCRIPTION_'+self.$store.getters.get_lang_code];

				extra_charges.push({
					id_service: extra_charge.IDSERVICE,
					has_image: has_image,
					image: image,
					title: title,
					description: description,
					quantity_selected: extra_charge.QUANTITY_SELECTED,
					import: extra_charge.IMPORT,
					import_formatted: extra_charge.IMPORT_FORMATTED
				});
			});
			return extra_charges;
		},
		discount_not_formatted: function() {
			let discount = 0;
			if(this.booking_type == 1) {
				for(let index = 0; index < this.$store.state.rooms.length; index++) {
					discount += this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
					let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
					for(let i = 0; i < selected_extra_charges.length; i++) {
						discount += selected_extra_charges[i].DISCOUNT * selected_extra_charges[i].QUANTITY_SELECTED;
					}
				}
			}
			return discount;
		},
		discount: function() {
			return this.discount_not_formatted().toFixed(2).replace(".", ",");
		},
		total_not_formatted: function() {
			let total = 0;
			if(this.booking_type == 1) {
				for(let index = 0; index < this.$store.state.rooms.length; index++) {
					total += this.$store.state.rooms[index].policy_selected.TOTAL_PRICE + this.$store.state.rooms[index].policy_selected.TOTAL_DISCOUNT;
					let selected_extra_charges = this.$store.getters.selected_extra_charges(index);
					for(let i = 0; i < selected_extra_charges.length; i++) {
						if(selected_extra_charges[i].DISCOUNT){
							total += (selected_extra_charges[i].IMPORT + selected_extra_charges[i].DISCOUNT) * selected_extra_charges[i].QUANTITY_SELECTED;
						}
						else {
							total += selected_extra_charges[i].IMPORT * selected_extra_charges[i].QUANTITY_SELECTED;
						}
					}
				}
			} else if(this.booking_type == 2) {
				total = this.$store.state.package.package_room_selected.TOTAL_PRICE;
				for(let i = 0; i < this.$store.getters.selected_package_extra_charges.length; i++) {
					total += this.$store.getters.selected_package_extra_charges[i].IMPORT * this.$store.getters.selected_package_extra_charges[i].QUANTITY_SELECTED;
				}
			}
			return total;
		},
		total: function() {
			return this.total_not_formatted().toFixed(2).replace(".", ",");
		},
		getPrivacyText: function() {
			let privacy_text = this.getTranslation('PRIVACYLAW');
			privacy_text = privacy_text.replace(/#COMPANY#/g, this.$store.state.company_info.name + ' - Partita IVA: ' + this.$store.state.company_info.vat);
			privacy_text = privacy_text.replace(/#EMAIL#/g, this.$store.state.company_info.email);
			return privacy_text;
		},
		privacyPopup: function() {
			window.$(`
				<div class="privacy_popup" id="privacy_popup">
					`+this.getPrivacyText()+`
				</div>
			`).appendTo('body').modal();
		},
		termsPopup: function() {
			window.$(`
				<div class="privacy_popup" id="privacy_popup">
					`+this.room_policy_description(0)+`
				</div>
			`).appendTo('body').modal();
		},
		resetCouponCode: function() {
			this.$store.dispatch('resetCouponCode');

			this.coupon_code = '';
		},
		applyCouponCode: function() {
			this.$store.commit('setCoupon', {
				code: this.coupon_code,
				status: 0,
				data: {}
			});

			this.$store.dispatch('applyCouponCode');

			this.coupon_code = '';
		},
		confirmPrenotation: function() {
			let all_ok = true;

			if(this.first_name == '') {
				$('#first_name').addClass('invalid_input');
				$('#first_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.last_name == '') {
				$('#last_name').addClass('invalid_input');
				$('#last_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.email == '') {
				$('#email').addClass('invalid_input');
				$('#email').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			} else {
				const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				if (!emailPattern.test(this.email)) {
					$('#email').addClass('invalid_input');
					$('#email').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('INVALID_EMAIL'));
					all_ok = false;
				}
			}

			if(this.email_confirm == '') {
				$('#email_confirm').addClass('invalid_input');
				$('#email_confirm').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.email != this.email_confirm) {
				$('#email').addClass('invalid_input');
				$('#email_confirm').addClass('invalid_input');
				$('#email_confirm').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('EMAILS_NOT_MATCH'));
				all_ok = false;
			}

			if(this.tel == '') {
				$('#tel').addClass('invalid_input');
				$('#tel').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.privacy_policy == '') {
				$('#privacy_policy').addClass('invalid_input');
				$('#privacy_policy').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.terms == '') {
				$('#terms').addClass('invalid_input');
				$('#terms').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
				all_ok = false;
			}

			if(this.check_bonifico == 1){
				if(this.payment_method == '') {
					// $('#payment_method').addClass('invalid_input');
					// $('#payment_method').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}
			}


			if(this.paymentMethod != 1 || this.check_bonifico == 0){
				if(this.cc_type == '') {
					$('#cc_type').addClass('invalid_input');
					$('#cc_type').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}
	
				if(this.cc_number == '') {
					$('#cc_number').addClass('invalid_input');
					$('#cc_number').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				} else if(this.cc_number.length < 13 || this.cc_number.length > 16) {
					$('#cc_number').addClass('invalid_input');
					$('#cc_number').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('VALUE_NOT_VALID'));
					all_ok = false;
				} else if (!/^\d+$/.test(this.cc_number)) { 
					$('#cc_number').addClass('invalid_input');
					$('#cc_number').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('VALUE_NOT_VALID'));
					all_ok = false;
				}
	
				if(this.cc_name == '') {
					$('#cc_name').addClass('invalid_input');
					$('#cc_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}
	
				if(this.cc_expire_month == '' && this.cc_expire_year == '') {
					$('#cc_expire_month').addClass('invalid_input');
					$('#cc_expire_year').addClass('invalid_input');
					$('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				} else if(this.cc_expire_month == '') {
					$('#cc_expire_month').addClass('invalid_input');
					$('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				} else if(this.cc_expire_year == '') {
					$('#cc_expire_year').addClass('invalid_input');
					$('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				} else {
					if(this.cc_expire_year == new Date().getFullYear() && this.cc_expire_month < new Date().getMonth()) {
						$('#cc_expire_month').addClass('invalid_input');
						$('label[for=cc_expire]').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('EXPIRATION_EXPIRED'));
						all_ok = false;
					}
				}

				if(this.cc_cvv == '') {
					$('#cc_cvv').addClass('invalid_input');
					$('#cc_cvv').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> ' + this.getTranslation('REQUIRED_FIELD'));
					all_ok = false;
				}else if (!/^\d{3}$/.test(this.cc_cvv)) {
					$('#cc_cvv').addClass('invalid_input');
					$('#cc_cvv').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> INVALID INPUT');
					all_ok = false;
				}
			}

			if(all_ok) {
				this.$store.commit('setCheckoutData', {
					first_name: this.first_name,
					last_name: this.last_name,
					email: this.email,
					email_confirm: this.email_confirm,
					tel: this.tel,
					country: this.country,
					notes: this.notes,
					privacy_policy: this.privacy_policy,
					terms: this.terms,
					cc_type: this.cc_type,
					cc_nickname: this.cc_type_nickname,
					cc_number: this.cc_number,
					cc_name: this.cc_name,
					cc_expire: this.cc_expire_month + '/' + this.cc_expire_year,
					cc_cvv: this.cc_cvv
				});

				if(this.booking_type == 1) {
					this.$store.dispatch('makeReservation');
				} else if(this.booking_type == 2) {
					this.$store.dispatch('makePackageReservation');
				}

				window.scrollTo(0, 0);
			}
		},
		goToPayment: function() {
			let all_ok = true;

			if(this.first_name == '') {
				$('#first_name').addClass('invalid_input');
				$('#first_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(this.last_name == '') {
				$('#last_name').addClass('invalid_input');
				$('#last_name').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(this.email == '') {
				$('#email').addClass('invalid_input');
				$('#email').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(this.email_confirm == '') {
				$('#email_confirm').addClass('invalid_input');
				$('#email_confirm').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(this.email != this.email_confirm) {
				$('#email').addClass('invalid_input');
				$('#email_confirm').addClass('invalid_input');
				$('#email_confirm').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Le password non coincidono');
				all_ok = false;
			}

			if(this.tel == '') {
				$('#tel').addClass('invalid_input');
				$('#tel').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(this.privacy_policy == '') {
				$('#privacy_policy').addClass('invalid_input');
				all_ok = false;
			}

			if(this.terms == '') {
				$('#terms').addClass('invalid_input');
				all_ok = false;
			}

			if(this.cc_type == '') {
				$('#cc_type').addClass('invalid_input');
				$('#cc_type').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i> Campo richiesto');
				all_ok = false;
			}

			if(all_ok) {
				this.$store.commit('setCheckoutData', {
					first_name: this.first_name,
					last_name: this.last_name,
					email: this.email,
					email_confirm: this.email_confirm,
					tel: this.tel,
					country: this.country,
					country_code: this.country_code,
					notes: this.notes,
					privacy_policy: this.privacy_policy,
					terms: this.terms,
					cc_type: this.cc_type,
					cc_nickname: this.cc_type_nickname,
					cc_number: '',
					cc_name: '',
					cc_expire: '',
					total: this.total_not_formatted(),
					lang_code: this.$store.getters.get_lang_code
				});

				if(this.booking_type == 1) {
					this.$store.dispatch('makeReservation');
				} else if(this.booking_type == 2) {
					this.$store.dispatch('makePackageReservation');
				}

				window.scrollTo(0, 0);
			}
		},
		backToSearch: function() {
			this.$store.dispatch('backToSearch');
		}
	}
}
</script>