import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueMeta from 'vue-meta'
import App from './App.vue'
import jQuery from 'jquery'
import Hotjar from 'vue-hotjar'
import Utility from './utility.js'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(Utility)
Vue.use(Hotjar, {
  id: '2964812'
})
// mirco 2901
const axiosInstance = axios.create({
    baseURL: 'https://core.isidorosoftware.com'
})

Vue.prototype.$axios = axiosInstance
Vuex.Store.prototype.$axios = axiosInstance

Vue.prototype.$baseURL = 'https://core.isidorosoftware.com'
Vuex.Store.prototype.$baseURL = 'https://core.isidorosoftware.com'

let params = window.location.pathname.split('/').slice(1)
Vue.prototype.$company = params[0]
Vuex.Store.prototype.$company = params[0]

if(params[1] == 'deleteReservation') {
    Vue.prototype.$type = 'delete'
    Vuex.Store.prototype.$type = 'delete'
} else if(params[1] == 'backToCheckout') {
    Vue.prototype.$type = 'checkout'
    Vuex.Store.prototype.$type = 'checkout'
} else if(params[1] == 'failedReservation') {
    Vue.prototype.$type = 'fail'
    Vuex.Store.prototype.$type = 'fail'
} else if(params[1] == 'confirmedReservation') {
    Vue.prototype.$type = 'paid'
    Vuex.Store.prototype.$type = 'paid'
} else if(params[1] == 'loadQuota') {
    Vue.prototype.$type = 'quotas'
    Vuex.Store.prototype.$type = 'quotas'
} else if(params[1] == 'requestQuota') {
    Vue.prototype.$type = 'requestQuota'
    Vuex.Store.prototype.$type = 'requestQuota'
} else {
    Vue.prototype.$type = 'booking'
    Vuex.Store.prototype.$type = 'booking'
}

const store = new Vuex.Store({
    state() {
        return {
            initialized: false,
            company_id: 1,
            booking_type: 1,
            step: 0,
            loading: false,
            search_changed: false,
            show_sidebar: true,
            lang: 0,
            company_info: {
                max_rooms: 999
            },
            translations: '',
            ages: {},
            room_types: {},
            tracking_codes: {},
            check_in: '',
            check_out: '',
            allotment: {
                code: '',
                ID: 0,
                fixed_date: {
                    fixed: 0,
                    check_in: '',
                    check_out: ''
                },
                error: false
            },
            current_room: 0,
            rooms: [
                {
                    adults: 2,
                    child1: 0,
                    child2: 0,
                    infants: 0,
                    package_selected: '',
                    room_selected: '',
                    policy_selected: '',
                    extra_charges: ''
                }
            ],
            room_type: 0,
            last_search: {
                check_in: '',
                check_out: '',
                current_room: '',
                adults: '',
                child1: '',
                child2: '',
                infants: '',
                room_type: ''
            },
            results: '',
            minimum_stay: '',
            other_availabilities: '',
            package: {
                adults: 2,
                child1: 0,
                child2: 0,
                infants: 0,
                package_selected: '',
                package_calendar: '',
                package_rooms: '',
                package_room_selected: '',
                extra_charges: '',
            },
            payment_bonifico: '', //1 BONIFICO, 2 CARTE
            amount_bonifico: '',
            checkout_data: '',
            coupon: {
                'code': '',
                'status': 0,
                'data': {}
            },
            reservation_id: '',
            payment_token: '',
            payment_error: '',
            quota: {
                quota_id: '',
                nrchoice: 1,
                validation_token: ''
            },
            quotas: '',
            quotas_newQty: '',
            quotas_checkin: '',
            quotas_checkout: '',
            quotas_total_guest: '',
            quotas_total_lodge: '',
            quotas_total_nights: '',
            quotas_total: '',
            quotas_total_correctionrate: '',
            quotas_total_price: '',
            quotas_amount: '',
            quotas_services: '',
            quotas_total_firstdayprice: '',
            quotas_commonData: '',
            quotas_policyDescription: '',
            quotas_emailFooter: '',
        }
    },
    getters: {
        get_lang_code: state => {
            switch(state.lang) {
                case 0:
                    return 'IT';
                case 1:
                    return 'EN';
                case 2:
                    return 'DE';
                case 3:
                    return 'FR';
                case 4:
                    return 'PT';
                case 5:
                    return 'ES';
                case 6:
                    return 'RU';
                case 7:
                    return 'CH';
                default:
                    return 'IT';
            }
        },
        get_date_lang_code: state => {
            switch(state.lang) {
                case 0:
                    return 'it-IT';
                case 1:
                    return 'en-GB';
                case 2:
                    return 'de-DE';
                case 3:
                    return 'fr-FR';
                case 4:
                    return 'pt-PT';
                case 5:
                    return 'es-ES';
                case 6:
                    return 'ru-RU';
                case 7:
                    return 'ja-JP';
                default:
                    return 'en-GB';
            }
        },
        get_translation: (state, getters) => (key) => {
            let string = '';
            if(state.translations) {
                string = state.translations[key][getters.get_lang_code];
                if(string == '') {
                    string = state.translations[key]['EN'];
                }
                if(string == '') {
                    string = state.translations[key]['IT'];
                }
            }
            return string;  
        },
        selected_extra_charges: state => (index) => {
            let selected_extra_charges = [];
            for(let i = 0; i < state.rooms[index].extra_charges.length; i++) {
                if(state.rooms[index].extra_charges[i].QUANTITY_SELECTED > 0) {
                    selected_extra_charges.push(state.rooms[index].extra_charges[i]);
                }
            }
            return selected_extra_charges;
        },
        selected_extra_charges_price_details: state => (index) => {
            let selected_extra_charges_price_details = [];
            for(let i = 0; i < state.rooms[index].extra_charges.length; i++) {
                if(state.rooms[index].extra_charges[i].QUANTITY_SELECTED > 0) {
                    selected_extra_charges_price_details.push(state.rooms[index].extra_charges[i].EXTRA_PRICE_DETAILS);
                }
            }
            return selected_extra_charges_price_details;
        },
        selected_package_extra_charges: state => {
            let selected_extra_charges = [];
            for(let i = 0; i < state.package.extra_charges.length; i++) {
                if(state.package.extra_charges[i].QUANTITY_SELECTED > 0) {
                    selected_extra_charges.push(state.package.extra_charges[i]);
                }
            }
            return selected_extra_charges;
        },
        selected_package_extra_charges_price_details: state => {
            let selected_extra_charges_price_details = [];
            for(let i = 0; i < state.package.extra_charges.length; i++) {
                if(state.package.extra_charges[i].QUANTITY_SELECTED > 0) {
                    selected_extra_charges_price_details.push(state.package.extra_charges[i].EXTRA_PRICE_DETAILS);
                }
            }
            return selected_extra_charges_price_details;
        },
        confirmation_html: (state, getters) => {
            let discount = 0;
            if(state.booking_type == 1) {
                for(let index = 0; index < state.rooms.length; index++) {
                    discount += state.rooms[index].policy_selected.TOTAL_DISCOUNT;
                    let selected_extra_charges = getters.selected_extra_charges(index);
                    for(let i = 0; i < selected_extra_charges.length; i++) {
                        discount += selected_extra_charges[i].DISCOUNT * selected_extra_charges[i].QUANTITY_SELECTED;
                    }
                }
            }
            discount = discount.toFixed(2).replace(".", ",");

            let total = 0;
            if(state.booking_type == 1) {
                for(let index = 0; index < state.rooms.length; index++) {
                    total += state.rooms[index].policy_selected.TOTAL_PRICE + state.rooms[index].policy_selected.TOTAL_DISCOUNT;
                    let selected_extra_charges = getters.selected_extra_charges(index);
                    for(let i = 0; i < selected_extra_charges.length; i++) {
                        total += (selected_extra_charges[i].IMPORT + selected_extra_charges[i].DISCOUNT) * selected_extra_charges[i].QUANTITY_SELECTED;
                    }
                }
            } else if(state.booking_type == 2) {
                total = state.package.package_room_selected.TOTAL_PRICE;
                for(let i = 0; i < getters.selected_package_extra_charges.length; i++) {
                    total += getters.selected_package_extra_charges[i].IMPORT * getters.selected_package_extra_charges[i].QUANTITY_SELECTED;
                }
            }
            total = total.toFixed(2).replace(".", ",");

            let summary = ``;

            summary += `<tr><td colspan="2" style="border: 2px solid #444; border-radius: 3px; padding: 10px 14px; background: #f6f6f6;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle">` + getters.get_translation('RESERVATION_NUMBER') + `</td><td style="font-size: 16px;"><b>BOL_` + state.reservation_id + `</b></td></tr></tbody></table></td></tr>`;

            summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle">` + getters.get_translation('NAME') + `<br/><b>` + state.checkout_data.first_name + `</b></td><td>` + getters.get_translation('SURNAME') + `<br/><b>` + state.checkout_data.last_name + `</b></td></tr>`;
            summary += `<tr><td>` + getters.get_translation('EMAIL') + `<br/><b>` + state.checkout_data.email + `</b></td><td>` + getters.get_translation('PHONE') + `<br/><b>` + state.checkout_data.tel + `</b></td></tr>`;
            if(state.checkout_data.notes != "") {
                summary += `<tr><td colspan="2">` + getters.get_translation('REQUESTS') + `<br/><b>` + state.checkout_data.notes + `</b></td></tr>`;
            }
            summary += `</tbody></table></td></tr>`;

            summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle">` + getters.get_translation('CHECKIN') + `<br/><b>` + Vue.prototype.$formatDateIt(state.last_search.check_in.replace(/-/g, '/')) + `</b></td><td>` + getters.get_translation('CHECKOUT') + `<br/><b>` + Vue.prototype.$formatDateIt(state.last_search.check_out.replace(/-/g, '/')) + `</b></td></tr></tbody></table></td></tr>`;


            if(state.booking_type == 1) {

                for(let r = 0; r < state.rooms.length; r++) {
                    let room = state.rooms[r];

                    summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;">`;

                    if(room.package_selected){
                        summary += `<h2 style="text-transform: uppercase;"><b>` + room.package_selected['PACKAGE_NAME_'+getters.get_lang_code] + `</b></h2>`;
                        summary += `<span>` + room.package_selected['PACKAGE_DESCRIPTION_'+getters.get_lang_code] + `</span><br/><br/>`;
                    }
                    
                    summary += `<h2 style="text-transform: uppercase;"><b>` + room.room_selected['NAME_'+getters.get_lang_code] + `</b></h2>`;
                    summary += `<span>` + room.room_selected['DESCRIPTION_'+getters.get_lang_code] + `</span>`;

                    summary += `<div style="margin-top: 20px; margin-bottom: 10px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><tbody><tr><td style="text-align: center;">` + getters.get_translation('ADULT') + `<br/><b>` + room.adults + `</b></td>`;
                    if(state.ages.child1_age != null) {
                        summary += `<td style="text-align: center;">` + getters.get_translation('CHILD') + ` (`+state.ages.child1_age+`)<br/><b>` + room.child1 + `</b></td>`;
                    }
                    if(state.ages.child2_age != null) {
                        summary += `<td style="text-align: center;">` + getters.get_translation('CHILD') + ` (`+state.ages.child2_age+`)<br/><b>` + room.child2 + `</b></td>`;
                    }
                    if(state.ages.infant_age != null) {
                        summary += `<td style="text-align: center;">` + getters.get_translation('INFANT') + ` (`+state.ages.infant_age+`)<br/><b>` + room.infants + `</b></td>`;
                    }
                    summary += `</tr><tbody></table></div>`;

                    summary += `<div style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;">`
                    if(room.policy_selected['ARRANGEMENT_NAME_'+getters.get_lang_code]){
                        summary += `<h4><b>` + room.policy_selected['ARRANGEMENT_NAME_'+getters.get_lang_code] + `</b></h4>`;
                    }
                    summary += `<h5><b>` + room.policy_selected['POLICY_NAME_'+getters.get_lang_code] + `</b></h5>`;
                    summary += `<span>` + room.policy_selected['POLICY_DESCRIPTION_'+getters.get_lang_code] + `</span><br/><br/>`;
                    summary += `<h3 style="text-align: right;">` + room.policy_selected.TOTAL_PRICE_FORMATTED + `€</h3></div>`;

                    summary += `<table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody>`;
                    for(let i = 0; i < getters.selected_extra_charges(r).length; i++) {
                        let selected_extra_charge = getters.selected_extra_charges(r)[i];
                        summary += `<tr><td colspan="2" style="padding-top: 10px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><tbody><tr><td style="vertical-align: middle"><b>` + selected_extra_charge['NAME_'+getters.get_lang_code] + `</b><br/>` + selected_extra_charge['DESCRIPTION_'+getters.get_lang_code] + `</td><td style="vertical-align: middle; min-width: 100px;"><h3 style="text-align: right;"><span style="font-size: small;">` + selected_extra_charge['QUANTITY_SELECTED'] + ` x</span> ` + selected_extra_charge['IMPORT_FORMATTED'] + `€</h3></td></tr></tbody></table></td></tr>`;
                    }

                    summary += `</tbody></table></td></tr>`;
                }

                if(state.coupon.status == 1) {
                    summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle"><b>` + getters.get_translation('DISCOUNT_APPLIED') + `</b></td><td style="vertical-align: middle"><h3 style="text-align: right;">` + discount + `€</h3></td></tr><tr><td>` + state.coupon.data.DESCRIPTION + ' (-' + parseInt(state.coupon.data.DISCOUNT) + '%)' + `</td></tr></tbody></table></td></tr>`;
                }

            } else if(state.booking_type == 2) {

                summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody>`;

                summary += `<tr><td>` + getters.get_translation('ADULT') + `<br/><b>` + state.package.adults + `</b></td>`;
                let c = 1;
                if(state.ages.child1_age != null) {
                    summary += `<td>` + getters.get_translation('CHILD') + ` (`+state.ages.child1_age+`)<br/><b>` + state.package.child1 + `</b></td>`;
                    c++;
                }
                if(state.ages.child2_age != null) {
                    if(c == 2) {
                        summary += `</tr><tr>`;
                    }
                    summary += `<td>` + getters.get_translation('CHILD') + ` (`+state.ages.child2_age+`)<br/><b>` + state.package.child2 + `</b></td>`;
                    c++;
                }
                if(state.ages.infant_age != null) {
                    if(c == 2) {
                        summary += `</tr><tr>`;
                    }
                    summary += `<td>` + getters.get_translation('INFANT') + ` (`+state.ages.infant_age+`)<br/><b>` + state.package.infants + `</b></td>`;
                    c++;
                }
                summary += `</tr></tbody></table></td></tr>`;

                let nights = Vue.prototype.$calculateNights(new Date(state.last_search.check_in), new Date(state.last_search.check_out));
                let nights_text = '';
                if(nights == 1) {
                    nights_text = getters.get_translation('NIGHT');
                } else {
                    nights_text = getters.get_translation('NIGHTS');
                }
                summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;">`;
                summary += `<h4 style="font-weight: 600;">` + getters.get_translation('PACKAGE') + ` `+ nights +` `+ nights_text +`</h4>`;
                summary += `<h2 style="text-transform: uppercase;"><b>` + state.package.package_selected['NAME_'+getters.get_lang_code] + `</b></h2>`;
                summary += `<span>` + state.package.package_selected['DESCRIPTION_'+getters.get_lang_code] + `</span>`;
                summary += `<br/><br/>`;
                summary += `<h4 style="font-weight: 600;">` + getters.get_translation('ROOM') + `</h4>`;
                summary += `<h2 style="text-transform: uppercase;"><b>` + state.package.package_room_selected['NAME_'+getters.get_lang_code] + `</b></h2>`;
                summary += `<span>` + state.package.package_room_selected['DESCRIPTION_'+getters.get_lang_code] + `</span><br/><br/>`;
                summary += `<h4><b>` + state.package.package_room_selected.POLICY['POLICY_NAME_'+getters.get_lang_code] + `</b></h4>`;
                summary += `<span>` + state.package.package_room_selected.POLICY['POLICY_DESCRIPTION_'+getters.get_lang_code] + `</span><br/><br/>`;
                summary += `<h3 style="text-align: right;">` + state.package.package_room_selected.TOTAL_PRICE_FORMATTED + `€</h3>`;
                summary += `</td></tr>`;

                for(let i = 0; i < getters.selected_package_extra_charges.length; i++) {
                    let selected_extra_charge = getters.selected_package_extra_charges[i];
                    summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle; min-width: 100px;"><b>` + selected_extra_charge['NAME_'+getters.get_lang_code] + `</b><br/>` + selected_extra_charge['DESCRIPTION_'+getters.get_lang_code] + `</td><td style="vertical-align: middle"><h3 style="text-align: right;"><span style="font-size: small;">` + selected_extra_charge['QUANTITY_SELECTED'] + ` x</span> ` + selected_extra_charge['IMPORT_FORMATTED'] + `€</h3></td></tr></tbody></table></td></tr>`;
                }

            }

            summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle"><b>` + getters.get_translation('TOTALPRICE') + `</b></td><td style="vertical-align: middle"><h3 style="text-align: right;">` + total + `€</h3></td></tr></tbody></table></td></tr>`;

            if(state.company_info.email_bottom_text != null && state.company_info.email_bottom_text['DESCRIPTION_'+getters.get_lang_code] != null && state.company_info.email_bottom_text['DESCRIPTION_'+getters.get_lang_code] != '') {
                let email_bottom_text = state.company_info.email_bottom_text['DESCRIPTION_'+getters.get_lang_code];
                email_bottom_text = email_bottom_text.replace(/#guest/g, state.checkout_data.last_name + ' ' + state.checkout_data.first_name);
                summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;">`+email_bottom_text+`</td></tr>`;
            }

            return summary;
        },
        confirmation_pdf_html: (state, getters) => {
            let discount = 0;
            if(state.booking_type == 1) {
                for(let index = 0; index < state.rooms.length; index++) {
                    discount += state.rooms[index].policy_selected.TOTAL_DISCOUNT;
                    let selected_extra_charges = getters.selected_extra_charges(index);
                    for(let i = 0; i < selected_extra_charges.length; i++) {
                        discount += selected_extra_charges[i].DISCOUNT * selected_extra_charges[i].QUANTITY_SELECTED;
                    }
                }
            }
            discount = discount.toFixed(2).replace(".", ",");

            let total = 0;
            if(state.booking_type == 1) {
                for(let index = 0; index < state.rooms.length; index++) {
                    total += state.rooms[index].policy_selected.TOTAL_PRICE + state.rooms[index].policy_selected.TOTAL_DISCOUNT;
                    let selected_extra_charges = getters.selected_extra_charges(index);
                    for(let i = 0; i < selected_extra_charges.length; i++) {
                        if(selected_extra_charges[i].DISCOUNT){
                            total += (selected_extra_charges[i].IMPORT + selected_extra_charges[i].DISCOUNT) * selected_extra_charges[i].QUANTITY_SELECTED;
                        }
                        else {
                            total += selected_extra_charges[i].IMPORT * selected_extra_charges[i].QUANTITY_SELECTED;
                        }
                    }
                }
            } else if(state.booking_type == 2) {
                total = state.package.package_room_selected.TOTAL_PRICE;
                for(let i = 0; i < getters.selected_package_extra_charges.length; i++) {
                    total += getters.selected_package_extra_charges[i].IMPORT * getters.selected_package_extra_charges[i].QUANTITY_SELECTED;
                }
            }
            total = total.toFixed(2).replace(".", ",");

            let html = `<div style="font-size: 14px;">`;
            if(state.payment_bonifico == 1) {
                html += `<div style="font-size: 16px;"><b>`+getters.get_translation('BANK_TRANSFER_REQUEST')+`:</b></div>`;
            }else{
                html += `<div style="font-size: 16px;"><b>`+getters.get_translation('THANKYOU')+`:</b></div>`;
            }
            html += `<br/><hr/><br/>`;

            html += `<div><b>`+state.company_info.name+`</b></div>`;
            html += `<div>`+state.company_info.address1+` - `+state.company_info.address2+` `+state.company_info.city+` (`+state.company_info.province+`)</div>`;
            html += `<div>`+state.company_info.email;
            if(state.company_info.phones != '') {
                html += ` - `+state.company_info.phones;
            }
            html += `</div><br/><hr/><br/>`;
            html += `<div style="font-size: 16px;"><b>`+getters.get_translation('RESERVATION_NUMBER')+`:</b> BOL_`+state.reservation_id+`</div>`;
            html += `<div><b>`+getters.get_translation('NAME')+`:</b> `+state.checkout_data.first_name+` &nbsp; &nbsp; <b>`+getters.get_translation('SURNAME')+`:</b> `+state.checkout_data.last_name+`</div>`;
            html += `<div><b>`+getters.get_translation('EMAIL')+`:</b> `+state.checkout_data.email+` &nbsp; &nbsp; <b>`+getters.get_translation('PHONE')+`:</b> `+state.checkout_data.tel+`</div>`;
            html += `<br/><hr/><br/>`;

            if(state.booking_type == 1) {
                for(let r = 0; r < state.rooms.length; r++) {
                    let room = state.rooms[r];

                    if(room.package_selected){
                        html += `<div style="font-size: 16px;"><b>`+room.package_selected['PACKAGE_NAME_'+getters.get_lang_code]+`</b></div>`;
                        html += `<div>`+room.package_selected['PACKAGE_DESCRIPTION_'+getters.get_lang_code]+`</div><br/>`;
                    }
                    
                    html += `<div style="font-size: 16px;"><b>`+room.room_selected['NAME_'+getters.get_lang_code]+`</b></div>`;
                    html += `<div>`+room.room_selected['DESCRIPTION_'+getters.get_lang_code]+`</div><br/>`;

                    html += `<div><b>`+getters.get_translation('ADULT')+`:</b> `+room.adults;
                    if(state.ages.child1_age != null) {
                        html += ` &nbsp; <b>`+getters.get_translation('CHILD')+` <span style="font-size: 12px;">(`+state.ages.child1_age+`)</span>:</b> `+room.child1;
                    }
                    if(state.ages.child2_age != null) {
                        html += ` &nbsp; <b>`+getters.get_translation('CHILD')+` <span style="font-size: 12px;">(`+state.ages.child2_age+`)</span>:</b> `+room.child2;
                    }
                    if(state.ages.infant_age != null) {
                        html += ` &nbsp; <b>`+getters.get_translation('INFANT')+` <span style="font-size: 12px;">(`+state.ages.infant_age+`)</span>:</b> `+room.infants;
                    }
                    html += `</div><br/>`;

                    if(room.policy_selected['ARRANGEMENT_NAME_'+getters.get_lang_code]){
                        html += `<div style="font-size: 16px;"><b>`+room.policy_selected['ARRANGEMENT_NAME_'+getters.get_lang_code]+`</b></div>`;
                    }
                    html += `<div><b>`+room.policy_selected['POLICY_NAME_'+getters.get_lang_code]+`</b></div>`;
                    html += `<div>`+room.policy_selected['POLICY_DESCRIPTION_'+getters.get_lang_code]+`</div><br/>`;
                    html += `<div style="text-align: right;"><b>`+room.policy_selected.TOTAL_PRICE_FORMATTED+`€</b></div><br/>`;

                    for(let i = 0; i < getters.selected_extra_charges(r).length; i++) {
                        let selected_extra_charge = getters.selected_extra_charges(r)[i];
                        html += `<div>+ <b>`+selected_extra_charge['NAME_'+getters.get_lang_code]+`:</b> `+selected_extra_charge['DESCRIPTION_'+getters.get_lang_code]+` (<span style="font-size: 12px;">`+selected_extra_charge['QUANTITY_SELECTED']+` x</span> <b>`+selected_extra_charge['IMPORT_FORMATTED']+`</b>)</div>`;
                    }
                    if(getters.selected_extra_charges(r).length > 0) {
                        html += `<br/>`;
                    }
                    html += `<hr/><br/>`;
                }

                if(state.coupon.status == 1) {
                    html += `<div><b>`+getters.get_translation('DISCOUNT_APPLIED')+`:</b> `+discount+`€</div>`;
                    html += `<div>(`+state.coupon.data.DESCRIPTION+`: `+parseInt(state.coupon.data.DISCOUNT)+`%)</div><br/><hr/><br/>`;
                }
            } else if(state.booking_type == 2) {
                html += `<div><b>`+getters.get_translation('ADULT')+`:</b> `+state.package.adults;
                if(state.ages.child1_age != null) {
                    html += ` &nbsp; <b>`+getters.get_translation('CHILD')+` <span style="font-size: 12px;">(`+state.ages.child1_age+`)</span>:</b> `+state.package.child1;
                }
                if(state.ages.child2_age != null) {
                    html += ` &nbsp; <b>`+getters.get_translation('CHILD')+` <span style="font-size: 12px;">(`+state.ages.child2_age+`)</span>:</b> `+state.package.child2;
                }
                if(state.ages.infant_age != null) {
                    html += ` &nbsp; <b>`+getters.get_translation('INFANT')+` <span style="font-size: 12px;">(`+state.ages.infant_age+`)</span>:</b> `+state.package.infants;
                }
                html += `</div><br/><hr/><br/>`;

                let nights = Vue.prototype.$calculateNights(new Date(state.last_search.check_in), new Date(state.last_search.check_out));
                let nights_text = '';
                if(nights == 1) {
                    nights_text = getters.get_translation('NIGHT');
                } else {
                    nights_text = getters.get_translation('NIGHTS');
                }

                html += `<div style="font-size: 16px;"><b>`+getters.get_translation('PACKAGE')+ ` `+ nights +` `+ nights_text +`</b></div>`;
                html += `<div><b>`+state.package.package_selected['NAME_'+getters.get_lang_code]+`</b></div><br/>`;
                html += `<div>`+state.package.package_selected['DESCRIPTION_'+getters.get_lang_code]+`</div><br/><br/>`;

                html += `<div><b>`+state.package.package_room_selected['NAME_'+getters.get_lang_code]+`</b></div><br/>`;
                html += `<div>`+state.package.package_room_selected['DESCRIPTION_'+getters.get_lang_code]+`</div><br/><br/>`;
                html += `<div><b>`+state.package.package_room_selected.POLICY['POLICY_NAME_'+getters.get_lang_code]+`</b></div><br/>`;
                html += `<div>`+state.package.package_room_selected.POLICY['POLICY_DESCRIPTION_'+getters.get_lang_code]+`</div><br/>`;
                html += `<div style="text-align: right;"><b>`+state.package.package_room_selected.TOTAL_PRICE_FORMATTED+`€</b></div><br/>`;

                for(let i = 0; i < getters.selected_package_extra_charges.length; i++) {
                    let selected_extra_charge = getters.selected_package_extra_charges[i];
                    html += `<div>+ <b>`+selected_extra_charge['NAME_'+getters.get_lang_code]+`:</b> `+selected_extra_charge['DESCRIPTION_'+getters.get_lang_code]+` (<span style="font-size: 12px;">`+selected_extra_charge['QUANTITY_SELECTED']+` x</span> <b>`+selected_extra_charge['IMPORT_FORMATTED']+`</b>)</div>`;
                }
                if(getters.selected_package_extra_charges.length > 0) {
                    html += `<br/>`;
                }
                html += `<hr/><br/>`;
            }

            html += `<div style="font-size: 20px; text-align: right;"><b>`+getters.get_translation('TOTALPRICE')+`: ` + total + `€</b></div>`;

            html += `</div>`;

            html += `<style>
                hr {
                    border: none;
                    height: 1px;
                    color: #333333;
                    background-color: #333333;
                }
            </style>`;

            return html;
        }
    },
    mutations: {
        setInitialized(state, initialized) {
            state.initialized = initialized;
            this._vm.$updateUrlParams(state);
        },
        setCompanyID(state, company_id) {
            state.company_id = company_id;
        },
        setBookingType(state, booking_type) {
            state.booking_type = booking_type;
            this._vm.$updateUrlParams(state);
        },
        setStep(state, step) {
            state.step = step;
            window.history.pushState({page: step}, "", "");
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setSearchChanged(state, search_changed) {
            state.search_changed = search_changed;
        },
        setShowSidebar(state, show_sidebar) {
            state.show_sidebar = show_sidebar;
        },
        setLang(state, lang) {
            state.lang = lang;
            this._vm.$updateUrlParams(state);
        },
        setCompanyInfo(state, company_info) {
            state.company_info = company_info;
        },
        setTranslations(state, translations) {
            state.translations = translations;
        },
        setAges(state, ages) {
            state.ages = ages;
        },
        setRoomTypes(state, room_types) {
            state.room_types = room_types;
        },
        setTrackingCodes(state, tracking_codes) {
            state.tracking_codes = tracking_codes;
        },
        setCheckIn(state, check_in) {
            if(state.check_in != check_in) {
                state.search_changed = true;
            }
            state.check_in = check_in;
            this._vm.$updateUrlParams(state);
        },
        setCheckOut(state, check_out) {
            if(state.check_out != check_out) {
                state.search_changed = true;
            }
            state.check_out = check_out;
            this._vm.$updateUrlParams(state);
        },
        setAllotmentCode(state, code) {
            state.allotment.code = code;
        },
        setAllotmentIdAndFixedDate(state, data) {
            state.allotment.ID = data.ID;
            state.allotment.fixed_date.fixed = data.fixed;
            state.allotment.fixed_date.check_in = data.check_in;
            state.allotment.fixed_date.check_out = data.check_out;
        },
        removeAllotmentCode(state) {
            state.allotment = {
                code: '',
                ID: 0,
                fixed_date: {
                    fixed: 0,
                    check_in: '',
                    check_out: ''
                },
                error: false
            }
        },
        setAllotmentError(state) {
            state.allotment.error = true;
        },
        removeAllotmentError(state) {
            state.allotment.error = false;
        },
        setCurrentRoom(state, index) {
            state.current_room = index;
        },
        setRooms(state, rooms) {
            state.rooms = rooms;
        },
        addRoom(state) {
            if(state.rooms.length < state.company_info.max_rooms) {
                state.rooms.push({
                    adults: 2,
                    child1: 0,
                    child2: 0,
                    infants: 0,
                    package_selected: '',
                    room_selected: '',
                    policy_selected: '',
                    extra_charges: ''
                });
                state.search_changed = true;
                this._vm.$updateUrlParams(state);
            }
            
        },
        removeRoom(state, index) {
            state.rooms.splice(index, 1);
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setAdults(state, {index, adults}) {
            state.rooms[index].adults = adults;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setChild1(state, {index, child1}) {
            state.rooms[index].child1 = child1;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setChild2(state, {index, child2}) {
            state.rooms[index].child2 = child2;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setInfants(state, {index, infants}) {
            state.rooms[index].infants = infants;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setRoomType(state, room_type) {
            state.room_type = room_type;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setLastSearch(state, last_search) {
            state.last_search = last_search;
        },
        setResults(state, results) {
            state.results = results;
        },
        setMinimumStay(state, minimum_stay) {
            state.minimum_stay = minimum_stay;
        },
        setOtherAvailabilities(state, other_availabilities) {
            state.other_availabilities = other_availabilities;
        },
        setRoomPackageSelected(state, room_package) {
            state.rooms[state.current_room].package_selected = room_package;
        },
        setRoomSelected(state, room) {
            state.rooms[state.current_room].room_selected = room;
        },
        setPolicySelected(state, policy) {
            state.rooms[state.current_room].policy_selected = policy;
        },
        setExtraCharges(state, extra_charges) {
            state.rooms[state.current_room].extra_charges = extra_charges;
        },
        updateExtraChargeQuantity(state, {id, quantity}) {
            if(state.booking_type == 1){
                for(let i = 0; i < state.rooms[state.current_room].extra_charges.length; i++) {
                    if(state.rooms[state.current_room].extra_charges[i].IDSERVICE == id) {
                        state.rooms[state.current_room].extra_charges[i].QUANTITY_SELECTED = quantity;
                        for(let extra_price_detail_key in state.rooms[state.current_room].extra_charges[i].EXTRA_PRICE_DETAILS) {
                            let extra_price_detail = state.rooms[state.current_room].extra_charges[i].EXTRA_PRICE_DETAILS[extra_price_detail_key];
                            extra_price_detail[0].QTY = quantity;
                        }
                    }
                }
            }else if(state.booking_type == 2){
                for(let i = 0; i < state.package.extra_charges.length; i++) {
                    if(state.package.extra_charges[i].IDSERVICE == id) {
                        state.package.extra_charges[i].QUANTITY_SELECTED = quantity;
                        for(let extra_price_detail_key in state.package.extra_charges[i].EXTRA_PRICE_DETAILS) {
                            let extra_price_detail = state.package.extra_charges[i].EXTRA_PRICE_DETAILS[extra_price_detail_key];
                            extra_price_detail[0].QTY = quantity;
                        }
                    }
                }
            }
        },
        setPackage(state, i_package) {
            state.package = i_package;
        },
        resetPackage(state) {
            state.package = {
                adults: state.package.adults,
                child1: state.package.child1,
                child2: state.package.child2,
                infants: state.package.infants,
                package_selected: '',
                package_calendar: '',
                package_rooms: '',
                package_room_selected: '',
                extra_charges: '',
            }
            this._vm.$updateUrlParams(state);
        },
        setPackageAdults(state, adults) {
            state.package.adults = adults;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setPackageChild1(state, child1) {
            state.package.child1 = child1;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setPackageChild2(state, child2) {
            state.package.child2 = child2;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setPackageInfants(state, infants) {
            state.package.infants = infants;
            state.search_changed = true;
            this._vm.$updateUrlParams(state);
        },
        setPackageSelected(state, b_package) {
            state.package.package_selected = b_package;
            this._vm.$updateUrlParams(state);
        },
        setPackageCalendar(state, calendar) {
            state.package.package_calendar = calendar;
        },
        setPackageRooms(state, rooms) {
            state.package.package_rooms = rooms;
        },
        setPackageRoomSelected(state, room_selected) {
            state.package.package_room_selected = room_selected;
        },
        setPackageExtraCharges(state, extra_charges) {
            state.package.extra_charges = extra_charges;
        },
        updatePackageExtraChargeQuantity(state, {id, quantity}) {
            for(let i = 0; i < state.package.extra_charges.length; i++) {
                if(state.package.extra_charges[i].IDSERVICE == id) {
                    state.package.extra_charges[i].QUANTITY_SELECTED = quantity;
                    for(let extra_price_detail_key in state.package.extra_charges[i].EXTRA_PRICE_DETAILS) {
                        let extra_price_detail = state.package.extra_charges[i].EXTRA_PRICE_DETAILS[extra_price_detail_key];
                        extra_price_detail[0].QTY = quantity;
                    }
                }
            }
        },
        setPaymentBonifico(state, payment_bonifico) {
            state.payment_bonifico = payment_bonifico;
        },
        setAmountBonifico(state, amount_bonifico) {
            state.amount_bonifico = amount_bonifico;
        },
        setCheckoutData(state, checkout_data) {
            state.checkout_data = checkout_data;
        },
        setCoupon(state, coupon) {
            state.coupon = coupon;
        },
        setReservationID(state, reservation_id) {
            state.reservation_id = reservation_id;
        },
        setPaymentToken(state, payment_token) {
            state.payment_token = payment_token;
        },
        setPaymentError(state, payment_error) {
            state.payment_error = payment_error;
        },
        setQuotaId(state, quota_id) {
            state.quota.quota_id = quota_id;
        },
        setQuotaNrchoice(state, nrchoice) {
            state.quota.nrchoice = nrchoice;
        },
        setQuotaValidation(state, validation_token) {
            state.quota.validation_token = validation_token;
        },
        setQuotas(state, quotas) {
            state.quotas = quotas;
        },
        setQuotasNewQty(state, quotas_newQty) {
            state.quotas_newQty = quotas_newQty;
        },
        setQuotasCheckin(state, quotas_checkin) {
            state.quotas_checkin = quotas_checkin;
        },
        setQuotasCheckout(state, quotas_checkout) {
            state.quotas_checkout = quotas_checkout;
        },
        setQuotasTotalguest(state, quotas_total_guest) {
            state.quotas_total_guest = quotas_total_guest;
        },
        setQuotasTotallodge(state, quotas_total_lodge) {
            state.quotas_total_lodge = quotas_total_lodge;
        },
        setQuotascommonData(state, quotas_commonData) {
            state.quotas_commonData = quotas_commonData;
        },
        setQuotasdayDetail(state, quotas_dayDetail) {
            state.quotas_dayDetail = quotas_dayDetail;
        },
        setQuotaspolicyDescription(state, quotas_policyDescription) {      
            state.quotas_policyDescription = quotas_policyDescription;
        },
        setQuotasdays(state, quotasdays) {
            state.quotasdays = quotasdays;
        },        
        setQuotasTotalnights(state, quotas_total_nights) {
            state.quotas_total_nights = quotas_total_nights;
        },
        setQuotasTotal(state, quotas_total) {
            state.quotas_total = quotas_total;
        },
        setQuotasTotalCorrectionrate(state, quotas_total_correctionrate) {
            state.quotas_total_correctionrate = quotas_total_correctionrate;
        },
        setQuotasTotalprice(state, quotas_total_price) {
            state.quotas_total_price = quotas_total_price;
        },
        setQuotasAmount(state, quotas_amount) {
            state.quotas_amount = quotas_amount
        },
        setQuotasServices(state, quotas_services) {
            state.quotas_services = quotas_services;
        },
        setQuotasFirstdayprice(state, quotas_total_firstdayprice) {
            state.quotas_total_firstdayprice = quotas_total_firstdayprice;
        },
        setQuotasEmailFooter(state, quotas_emailFooter) {
            state.quotas_emailFooter = quotas_emailFooter;
        }
    },
    actions: {
        async getSearchResults(context) {
            var self = this;

            context.commit('setLoading', true);
            context.commit('setLastSearch', {
                check_in: context.state.check_in,
                check_out: context.state.check_out,
                current_room: context.state.current_room,
                adults: context.state.rooms[context.state.current_room].adults,
                child1: context.state.rooms[context.state.current_room].child1,
                child2: context.state.rooms[context.state.current_room].child2,
                infants: context.state.rooms[context.state.current_room].infants,
                room_type: context.state.room_type,
                allotment: context.state.allotment
            });
            context.commit('setSearchChanged', false);

            if(context.state.allotment.ID > 0) {
                var bodyFormDataAllotment = new FormData();
                bodyFormDataAllotment.append('check_in', context.state.last_search.check_in);
                bodyFormDataAllotment.append('check_out', context.state.last_search.check_out);
                bodyFormDataAllotment.append('adult', context.state.rooms[context.state.current_room].adults);
                bodyFormDataAllotment.append('child1', context.state.rooms[context.state.current_room].child1);
                bodyFormDataAllotment.append('child2', context.state.rooms[context.state.current_room].child2);
                bodyFormDataAllotment.append('infant', context.state.rooms[context.state.current_room].infants);
                bodyFormDataAllotment.append('room_type', context.state.room_type);
                bodyFormDataAllotment.append('allotment_id', context.state.allotment.ID);

                self.$axios({
                    method: 'post',
                    url: '/booking/'+self.$company+'/getAllotmentAvailability/'+context.state.company_id,
                    data: bodyFormDataAllotment
                }).then(function (response) {
                    context.commit('setResults', response.data);
                    context.commit('setLoading', false);
                });
            } else {
                var bodyFormData = new FormData();
                bodyFormData.append('check_in', context.state.last_search.check_in);
                bodyFormData.append('check_out', context.state.last_search.check_out);
                bodyFormData.append('adult', context.state.rooms[context.state.current_room].adults);
                bodyFormData.append('child1', context.state.rooms[context.state.current_room].child1);
                bodyFormData.append('child2', context.state.rooms[context.state.current_room].child2);
                bodyFormData.append('infant', context.state.rooms[context.state.current_room].infants);
                bodyFormData.append('room_type', context.state.room_type);
                
                self.$axios({
                    method: 'post',
                    url: '/booking/'+self.$company+'/getAvailability/'+context.state.company_id,
                    data: bodyFormData
                }).then(function (response) {
                    context.commit('setResults', response.data);
                    context.commit('setMinimumStay', '');
                    context.commit('setOtherAvailabilities', '');

                    if(context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'] != undefined && (!context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'].includes('fbq') || window.fbq != null)) {
                        let tracking_code = context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'];

                        let travelers  = 0;
                        for(let index = 0; index < context.state.rooms.length; index++) {
                            travelers += context.state.rooms[index].adults + context.state.rooms[index].child1 + context.state.rooms[index].child2 + context.state.rooms[index].infants;
                        }

                        tracking_code = tracking_code.replace(/%ci/g, context.state.last_search.check_in);
                        tracking_code = tracking_code.replace(/%co/g, context.state.last_search.check_out);
                        tracking_code = tracking_code.replace(/%r/g, context.state.rooms.length);
                        tracking_code = tracking_code.replace(/%t/g, travelers);

                        eval(tracking_code);
                    }

                    if((response.data == '' || response.data.length == 0) && context.state.current_room == 0) {
                        var bodyFormData = new FormData();
                        bodyFormData.append('check_in', context.state.last_search.check_in);
                        bodyFormData.append('check_out', context.state.last_search.check_out);
                        bodyFormData.append('adult', context.state.rooms[context.state.current_room].adults);
                        bodyFormData.append('child1', context.state.rooms[context.state.current_room].child1);
                        bodyFormData.append('child2', context.state.rooms[context.state.current_room].child2);
                        bodyFormData.append('infant', context.state.rooms[context.state.current_room].infants);
                        bodyFormData.append('room_type', context.state.room_type);
                        
                        self.$axios({
                            method: 'post',
                            url: '/booking/'+self.$company+'/getMoreNightsAvailabilities/'+context.state.company_id,
                            data: bodyFormData
                        }).then(function (response) {
                            if(response.data != '') {
                                context.commit('setMinimumStay', response.data);
                                context.commit('setLoading', false);
                            } else {
                                var bodyFormData = new FormData();
                                bodyFormData.append('check_in', context.state.last_search.check_in);
                                bodyFormData.append('check_out', context.state.last_search.check_out);
                                bodyFormData.append('guests', context.state.rooms[context.state.current_room].adults + context.state.rooms[context.state.current_room].child1 + context.state.rooms[context.state.current_room].child2);

                                self.$axios({
                                    method: 'post',
                                    url: '/booking/' + self.$company + '/getNearDaysAvailabilities/' + context.state.company_id,
                                    data: bodyFormData,
                                    timeout: 5000
                                }).then(function (response) {
                                    context.commit('setOtherAvailabilities', response.data);
                                    context.commit('setLoading', false);
                                }).catch(function () {
                                    context.commit('setOtherAvailabilities', {});
                                    context.commit('setLoading', false);
                                });
                            }
                        });
                    } else if(response.data == '' || response.data.length == 0) {
                        context.commit('setOtherAvailabilities', '');
                        context.commit('setLoading', false);
                    } else {
                        context.commit('setLoading', false);
                    }
                    context.commit('setStep', 1);
                    jQuery('html,body').scrollTop(0);

                    context.commit('setInitialized', true);
                });
            }
        },
        async getPackagesSearchResults(context) {
            context.commit('setLoading', true);
            context.commit('setLastSearch', {
                check_in: '',
                check_out: '',
                adults: context.state.package.adults,
                child1: context.state.package.child1,
                child2: context.state.package.child2,
                infants: context.state.package.infants,
                room_type: context.state.room_type
            });
            context.commit('setSearchChanged', false);

            var bodyFormData = new FormData();
            bodyFormData.append('adult', context.state.package.adults);
            bodyFormData.append('child1', context.state.package.child1);
            bodyFormData.append('child2', context.state.package.child2);
            bodyFormData.append('infant', context.state.package.infants);
            bodyFormData.append('room_type', context.state.room_type);
            
            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/getPackages/'+context.state.company_id,
                data: bodyFormData
            }).then(function (response) {
                context.commit('setResults', response.data);

                if(context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'] != undefined && (!context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'].includes('fbq') || window.fbq != null)) {
                    let tracking_code = context.state.tracking_codes['TRACKING_SCRIPT_SEARCH'];

                    tracking_code = tracking_code.replace(/%ci/g, '');
                    tracking_code = tracking_code.replace(/%co/g, '');
                    tracking_code = tracking_code.replace(/%r/g, '1');
                    tracking_code = tracking_code.replace(/%t/g, context.state.package.adults + context.state.package.child1 + context.state.package.child2 + context.state.package.infants);
                    
                    eval(tracking_code);
                }

                let urlParams = new URLSearchParams(window.location.search);
                let package_id = urlParams.get('package');
                if(package_id != null) {
                    for(let i = 0; i < context.state.results.length; i++) {
                        if(context.state.results[i].ID == package_id) {
                            context.commit('setPackageSelected', context.state.results[i]);
                            context.dispatch('getPackagesSearchCalendar');
                            break;
                        }
                    }
                }

                context.commit('setLoading', false);               
                context.commit('setStep', 1);
                context.commit('setInitialized', true);
            });
        },
        async getPackagesSearchCalendar(context) {
            context.commit('setLoading', true);

            var bodyFormData = new FormData();
            bodyFormData.append('validity_days', context.state.package.package_selected.VALIDITYDAYS);
            bodyFormData.append('package_id', context.state.package.package_selected.ID);
            bodyFormData.append('period', context.state.check_in);
            bodyFormData.append('nights', context.state.package.package_selected.NRDAYS);
            bodyFormData.append('adult', context.state.package.adults);
            bodyFormData.append('child1', context.state.package.child1);
            bodyFormData.append('child2', context.state.package.child2);
            bodyFormData.append('infant', context.state.package.infants);
            bodyFormData.append('room_type', context.state.room_type);
            bodyFormData.append('policy_id', context.state.package.package_selected.IDPOLICY);
            
            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/getPackageMonthAvailabilities/'+context.state.company_id,
                data: bodyFormData
            }).then(function (response) {
                context.commit('setPackageCalendar', response.data);
                if(response.data == '' || response.data.length == 0) {
                    context.commit('setLoading', false);
                } else {
                    context.commit('setLoading', false);
                }               
                context.commit('setStep', 1.1);
            });
        },
        async getPackagesSearchRooms(context) {
            context.commit('setLoading', true);

            context.commit('setLastSearch', {
                check_in: context.state.check_in,
                check_out: context.state.check_out,
                adults: context.state.package.adults,
                child1: context.state.package.child1,
                child2: context.state.package.child2,
                infants: context.state.package.infants,
                room_type: context.state.room_type
            });

            var bodyFormData = new FormData();
            bodyFormData.append('validity_days', context.state.package.package_selected.VALIDITYDAYS);
            bodyFormData.append('package_id', context.state.package.package_selected.ID);
            bodyFormData.append('check_in', context.state.last_search.check_in);
            bodyFormData.append('check_out', context.state.last_search.check_out);
            bodyFormData.append('nights', context.state.package.package_selected.NRDAYS);
            bodyFormData.append('adult', context.state.package.adults);
            bodyFormData.append('child1', context.state.package.child1);
            bodyFormData.append('child2', context.state.package.child2);
            bodyFormData.append('infant', context.state.package.infants);
            bodyFormData.append('room_type', context.state.room_type);
            bodyFormData.append('policy_id', context.state.package.package_selected.IDPOLICY);
            
            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/getPackageDayPrices/'+context.state.company_id,
                data: bodyFormData
            }).then(function (response) {
                context.commit('setPackageRooms', response.data);
                if(response.data == '' || response.data.length == 0) {
                    context.commit('setLoading', false);
                } else {
                    context.commit('setLoading', false);
                }               
                context.commit('setStep', 1.2);
            });
        },
        async getExtraCharges(context) {
            context.commit('setStep', 2);
            var bodyFormData = new FormData();
            if(context.state.rooms[context.state.current_room].package_selected != '') {
                bodyFormData.append('package_id', context.state.rooms[context.state.current_room].package_selected.PACKAGE_ID);
                bodyFormData.append('room_id', context.state.rooms[context.state.current_room].room_selected.ID);
                bodyFormData.append('check_in', context.state.last_search.check_in);
                bodyFormData.append('check_out', context.state.last_search.check_out);

                this.$axios({
                    method: 'post',
                    url: '/booking/'+this.$company+'/getPackageExtraCharges',
                    data: bodyFormData
                }).then(function (response) {
                    let extra_charges = response.data;
                    context.commit('setExtraCharges', extra_charges);
                    if(!Array.isArray(extra_charges) || extra_charges.length == 0) {
                        //Andrea aggiunto controllo
                        if(context.state.current_room == context.state.rooms.length - 1 || context.state.booking_type == 2){
                            context.commit('setStep', 3);
                        } else {
                            context.commit('setCurrentRoom', context.state.current_room + 1);
                            context.commit('setStep', 1);
                            context.dispatch('getSearchResults');
                        }
                    }
                });
            } else {
                bodyFormData.append('check_in', context.state.last_search.check_in);
                bodyFormData.append('room_id', context.state.rooms[context.state.current_room].room_selected.ID);
                bodyFormData.append('chargefrequency', context.state.rooms[context.state.current_room].policy_selected.POLICY_CHARGEFREQUENCY);
                bodyFormData.append('price_details', JSON.stringify(context.state.rooms[context.state.current_room].policy_selected.PRICE_DETAILS));
                
                this.$axios({
                    method: 'post',
                    url: '/booking/'+this.$company+'/getExtraCharges',
                    data: bodyFormData
                }).then(function (response) {
                    let extra_charges = response.data;
                    context.commit('setExtraCharges', extra_charges);
                    if(!Array.isArray(extra_charges) || extra_charges.length == 0) {
                        if(context.state.current_room == context.state.rooms.length - 1) {
                            context.commit('setStep', 3);
                        } else {
                            context.commit('setCurrentRoom', context.state.current_room + 1);
                            context.commit('setStep', 1);
                            context.dispatch('getSearchResults');
                        }
                    }
                });
            }            
        },
        async getPackageExtraCharges(context) {
            context.commit('setStep', 2);

            var bodyFormData = new FormData();
            bodyFormData.append('package_id', context.state.package.package_selected.ID);
            bodyFormData.append('room_id', context.state.package.package_room_selected.ID);
            bodyFormData.append('check_in', context.state.last_search.check_in);
            bodyFormData.append('check_out', context.state.last_search.check_out);

            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/getPackageExtraCharges',
                data: bodyFormData
            }).then(function (response) {
                let extra_charges = response.data;
                context.commit('setPackageExtraCharges', extra_charges);
                if(!Array.isArray(extra_charges) || extra_charges.length == 0) {
                    context.commit('setStep', 3);
                }
            });
        },
        async getAllotmentIdAndFixedDate(context) {
            this.$axios({
                method: 'get',
                url: '/booking/'+this.$company+'/getAllotmentIdAndFixedDate/'+context.state.allotment.code
            }).then(function (response) {
                if(response.data == -1) {
                    context.commit('setAllotmentError');
                } else {
                    context.commit('setSearchChanged', true);
                    context.commit('setAllotmentIdAndFixedDate', response.data);
                    if(context.state.allotment.fixed_date.fixed) {
                        context.commit('setCheckIn', context.state.allotment.fixed_date.check_in);
                        context.commit('setCheckOut', context.state.allotment.fixed_date.check_out);
                    }
                }
            });
        },
        async applyCouponCode(context) {
            var bodyFormData = new FormData();
            bodyFormData.append('coupon_code', context.state.coupon.code);
            bodyFormData.append('check_in', context.state.last_search.check_in);
            bodyFormData.append('check_out', context.state.last_search.check_out);

            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/checkCouponCode',
                data: bodyFormData
            }).then(function (response) {
                let coupon = response.data;
                if(coupon == null) {
                    context.commit('setCoupon', {
                        code: context.state.coupon.code,
                        status: -1,
                        data: {}
                    });

                    for(let index = 0; index < context.state.rooms.length; index++) {
                        let price_details = context.state.rooms[index].policy_selected.PRICE_DETAILS;
                        for(const date in price_details) {
                            let price_details_date_items = price_details[date];
                            for(const item in price_details_date_items) {
                                price_details_date_items[item].DISCOUNT = 0;
                            }
                        }

                        let automatic_extra_charges = context.state.rooms[index].policy_selected.EXTRA_PRICE_DETAILS;
                        for(const date in automatic_extra_charges) {
                            let automatic_extra_charges_date_items = automatic_extra_charges[date];
                            for(const item in automatic_extra_charges_date_items) {
                                automatic_extra_charges_date_items[item].DISCOUNT = 0;
                            }
                        }

                        context.state.rooms[index].policy_selected.TOTAL_DISCOUNT = 0;
                        
                        let extra_charges = context.state.rooms[index].extra_charges;
                        for(const ec_index in extra_charges) {
                            let extra_charge = extra_charges[ec_index];

                            let extra_charge_details = extra_charge.EXTRA_PRICE_DETAILS;
                            for(const date in extra_charge_details) {
                                let extra_charge_details_items = extra_charge_details[date];
                                for(const item in extra_charge_details_items) {
                                    extra_charge_details_items[item].DISCOUNT = 0;
                                }
                            }

                            extra_charge.DISCOUNT = 0;
                        }
                    }
                } else {
                    context.commit('setCoupon', {
                        code: context.state.coupon.code,
                        status: 1,
                        data: coupon
                    });

                    let discount = parseFloat(coupon.DISCOUNT);
                    let services = parseFloat(coupon.SERVICES);

                    for(let index = 0; index < context.state.rooms.length; index++) {
                        let total_discount = 0;

                        let price_details = context.state.rooms[index].policy_selected.PRICE_DETAILS;
                        for(const date in price_details) {
                            if(date.localeCompare(coupon.CHECKIN) >= 0 && date.localeCompare(coupon.CHECKOUT) <= 0) {
                                let price_details_date_items = price_details[date];
                                for(const item in price_details_date_items) {
                                    price_details_date_items[item].DISCOUNT = -(price_details_date_items[item].PRICE * discount / 100);
                                    total_discount += price_details_date_items[item].DISCOUNT;
                                }
                            }
                        }

                        let automatic_extra_charges = context.state.rooms[index].policy_selected.EXTRA_PRICE_DETAILS;
                        for(const date in automatic_extra_charges) {
                            if(date.localeCompare(coupon.CHECKIN) >= 0 && date.localeCompare(coupon.CHECKOUT) <= 0) {
                                let automatic_extra_charges_date_items = automatic_extra_charges[date];
                                for(const item in automatic_extra_charges_date_items) {
                                    automatic_extra_charges_date_items[item].DISCOUNT = -(automatic_extra_charges_date_items[item].IMPORT * discount / 100);
                                    if(automatic_extra_charges_date_items[item].TAKE > 0) {
                                        total_discount += automatic_extra_charges_date_items[item].DISCOUNT;
                                    }
                                }
                            }
                        }

                        context.state.rooms[index].policy_selected.TOTAL_DISCOUNT = total_discount;
                        
                        if(services) {
                            let extra_charges = context.state.rooms[index].extra_charges;
                            for(const ec_index in extra_charges) {
                                let extra_charge = extra_charges[ec_index];

                                let total_discount = 0;
                                let extra_charge_details = extra_charge.EXTRA_PRICE_DETAILS;
                                for(const date in extra_charge_details) {
                                    if(date.localeCompare(coupon.CHECKIN) >= 0 && date.localeCompare(coupon.CHECKOUT) <= 0) {
                                        let extra_charge_details_items = extra_charge_details[date];
                                        for(const item in extra_charge_details_items) {
                                            extra_charge_details_items[item].DISCOUNT = -(extra_charge_details_items[item].IMPORT * discount / 100);
                                            if(extra_charge_details_items[item].TAKE > 0) {
                                                total_discount += extra_charge_details_items[item].DISCOUNT;
                                            }
                                        }
                                    }
                                }

                                extra_charge.DISCOUNT = total_discount;
                            }
                        }
                    }
                }
            });
        },
        async resetCouponCode(context) {
            context.commit('setCoupon', {
                code: '',
                status: 0,
                data: {}
            });

            for(let index = 0; index < context.state.rooms.length; index++) {
                let price_details = context.state.rooms[index].policy_selected.PRICE_DETAILS;
                for(const date in price_details) {
                    let price_details_date_items = price_details[date];
                    for(const item in price_details_date_items) {
                        price_details_date_items[item].DISCOUNT = 0;
                    }
                }

                let automatic_extra_charges = context.state.rooms[index].policy_selected.EXTRA_PRICE_DETAILS;
                for(const date in automatic_extra_charges) {
                    let automatic_extra_charges_date_items = automatic_extra_charges[date];
                    for(const item in automatic_extra_charges_date_items) {
                        automatic_extra_charges_date_items[item].DISCOUNT = 0;
                    }
                }

                context.state.rooms[index].policy_selected.TOTAL_DISCOUNT = 0;
                
                let extra_charges = context.state.rooms[index].extra_charges;
                for(const ec_index in extra_charges) {
                    let extra_charge = extra_charges[ec_index];

                    let extra_charge_details = extra_charge.EXTRA_PRICE_DETAILS;
                    for(const date in extra_charge_details) {
                        let extra_charge_details_items = extra_charge_details[date];
                        for(const item in extra_charge_details_items) {
                            extra_charge_details_items[item].DISCOUNT = 0;
                        }
                    }

                    extra_charge.DISCOUNT = 0;
                }
            }
        },
        async makeReservation(context) {
            context.commit('setLoading', true);
            context.commit('setStep', 4);

            var bodyFormData = new FormData();
            bodyFormData.append('type', 1);
            bodyFormData.append('last_name', context.state.checkout_data.last_name);
            bodyFormData.append('first_name', context.state.checkout_data.first_name);
            bodyFormData.append('tel', context.state.checkout_data.tel);
            bodyFormData.append('email', context.state.checkout_data.email);
            bodyFormData.append('country', context.state.checkout_data.country);
            bodyFormData.append('cc_type', context.state.checkout_data.cc_type);
            bodyFormData.append('cc_nickname', context.state.checkout_data.cc_nickname);
            bodyFormData.append('cc_number', context.state.checkout_data.cc_number);
            bodyFormData.append('cc_expire', context.state.checkout_data.cc_expire);
            bodyFormData.append('cc_cvv', context.state.checkout_data.cc_cvv);
            if(context.state.payment_bonifico == 1){
                bodyFormData.append('cc_name', context.state.amount_bonifico);
            }else{
                bodyFormData.append('cc_name', context.state.checkout_data.cc_name);
            }
            bodyFormData.append('lang', context.state.lang);
            bodyFormData.append('notes', context.state.checkout_data.notes);
            bodyFormData.append('check_in', context.state.last_search.check_in);
            bodyFormData.append('check_out', context.state.last_search.check_out);
            bodyFormData.append('checkout_notes', '');
            if(context.state.coupon.status == 1) {
                bodyFormData.append('discount_rate', context.state.coupon.data.DISCOUNT);
                bodyFormData.append('id_group_reservation', context.state.coupon.data.ID);
            } else {
                bodyFormData.append('discount_rate', 0);
                bodyFormData.append('id_group_reservation', 0);
            }
            bodyFormData.append('id_allotment', context.state.allotment.ID);

            var toPay = false;
            if(context.state.checkout_data.cc_nickname == 'SCALAPAY' || context.state.checkout_data.cc_nickname == 'NEXI PAY') {
                toPay = true;
                bodyFormData.append('dump', JSON.stringify(context.state));
            }

            let rooms = [];
            for(let i = 0; i < context.state.rooms.length; i++) {
                rooms.push({
                    basicarrangement_id: context.state.rooms[i].policy_selected.IDBASICARRANGEMENT,
                    room_id: context.state.rooms[i].room_selected.ID,
                    adults: context.state.rooms[i].adults,
                    child1: context.state.rooms[i].child1,
                    child2: context.state.rooms[i].child2,
                    infants: context.state.rooms[i].infants,
                    arrangement_id: context.state.rooms[i].policy_selected.ARRANGEMENT_ID,
                    taxrate_id: context.state.rooms[i].policy_selected.IDTAXRATE,
                    prices: context.state.rooms[i].policy_selected.PRICE_DETAILS,
                    automatic_extra_charges: context.state.rooms[i].policy_selected.EXTRA_PRICE_DETAILS,
                    selected_extra_charges: context.getters.selected_extra_charges_price_details(i)
                });
            }
            bodyFormData.append('rooms', JSON.stringify(rooms));

            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/makeReservationV2',
                data: bodyFormData
            }).then(function (response) {
                if(toPay) {
                    let res = response.data;
                    if(res['payment_method'] == 'SCALAPAY') {
                        let checkoutUrl = res['checkout_url'];
                        window.location.href = checkoutUrl;
                    } else if(res['payment_method'] == 'NEXI PAY') { 
                        var form = jQuery('<form>', {
                          'action': res['url_api'],
                          'method': 'POST'
                        });

                        Object.keys(res['body']).forEach((key) => {
                            form.append(jQuery('<input>', {
                                'name': key,
                                'value': res['body'][key],
                                'type': 'hidden'
                            }))
                        });

                        form.appendTo('body').submit();
                    }

                    setTimeout(function() {
                        context.commit('setLoading', false);
                    }, 4000);
                } else {
                    let reservation_id = response.data;
                    if(typeof reservation_id == "string") {
                        reservation_id = reservation_id.trim();
                    }
                    if(reservation_id != '' && !isNaN(reservation_id) && reservation_id > 0) {
                        context.commit('setReservationID', reservation_id);
                        context.dispatch('sendConfirmationEmail');
                    } else {
                        context.commit('setReservationID', -1);
                    }

                    context.commit('setLoading', false);
                }
            });
        },
        async makePackageReservation(context) {
            context.commit('setLoading', true);
            context.commit('setStep', 4);

            var bodyFormData = new FormData();
            bodyFormData.append('type', 2);
            bodyFormData.append('last_name', context.state.checkout_data.last_name);
            bodyFormData.append('first_name', context.state.checkout_data.first_name);
            bodyFormData.append('tel', context.state.checkout_data.tel);
            bodyFormData.append('email', context.state.checkout_data.email);
            bodyFormData.append('country', context.state.checkout_data.country);
            bodyFormData.append('cc_type', context.state.checkout_data.cc_type);
            bodyFormData.append('cc_nickname', context.state.checkout_data.cc_nickname);
            bodyFormData.append('cc_number', context.state.checkout_data.cc_number);
            bodyFormData.append('cc_expire', context.state.checkout_data.cc_expire);
            bodyFormData.append('cc_cvv', context.state.checkout_data.cc_cvv);
            if(context.state.payment_bonifico == 1){
                bodyFormData.append('cc_name', context.state.amount_bonifico);
            }else{
                bodyFormData.append('cc_name', context.state.checkout_data.cc_name);
            }
            bodyFormData.append('lang', context.state.lang);
            bodyFormData.append('notes', context.state.checkout_data.notes);
            bodyFormData.append('basicarrangement_id', 0);
            bodyFormData.append('check_in', context.state.last_search.check_in);
            bodyFormData.append('check_out', context.state.last_search.check_out);
            bodyFormData.append('room_id', context.state.package.package_room_selected.ID);
            bodyFormData.append('adults', context.state.package.adults);
            bodyFormData.append('child1', context.state.package.child1);
            bodyFormData.append('child2', context.state.package.child2);
            bodyFormData.append('infants', context.state.package.infants);
            bodyFormData.append('arrangement_id', context.state.package.package_room_selected.ARRANGEMENT_ID);
            bodyFormData.append('taxrate_id', context.state.package.package_selected.IDTAXRATE);
            bodyFormData.append('prices', JSON.stringify(context.state.package.package_room_selected.ROOM_PRICES));
            bodyFormData.append('automatic_extra_charges', JSON.stringify(context.state.package.package_room_selected.EXTRA_PRICES));
            bodyFormData.append('selected_extra_charges', JSON.stringify(context.getters.selected_package_extra_charges_price_details));
            bodyFormData.append('payment_token', context.state.payment_token);
            bodyFormData.append('id_allotment', context.state.allotment.ID);

            var toPay = false;
            if(context.state.checkout_data.cc_nickname == 'SCALAPAY' || context.state.checkout_data.cc_nickname == 'NEXI PAY') {
                toPay = true;
                bodyFormData.append('dump', JSON.stringify(context.state));
            }

            let urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('orderToken')) {
                bodyFormData.append('checkout_notes', 'Identificativo pagamento: ' + urlParams.get('orderToken'));
            } else {
                bodyFormData.append('checkout_notes', '');
            }
            
            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/makeReservation',
                data: bodyFormData
            }).then(function (response) {
                if(toPay) {
                    let res = response.data;

                    if(res['payment_method'] == 'SCALAPAY') {
                        let checkoutUrl = res['checkout_url'];
                        window.location.href = checkoutUrl;
                    } else if(res['payment_method'] == 'NEXI PAY') { 
                        var form = jQuery('<form>', {
                          'action': res['url_api'],
                          'method': 'POST'
                        });

                        Object.keys(res['body']).forEach((key) => {
                            form.append(jQuery('<input>', {
                                'name': key,
                                'value': res['body'][key],
                                'type': 'hidden'
                            }))
                        });

                        form.appendTo('body').submit();
                    }

                    setTimeout(function() {
                        context.commit('setLoading', false);
                    }, 4000);
                } else {
                    let reservation_id = response.data;
                        if(typeof reservation_id == "string") {
                        reservation_id = reservation_id.trim();
                    }
                    if(reservation_id != '' && !isNaN(reservation_id) && reservation_id > 0) {
                        context.commit('setReservationID', reservation_id);
                        context.dispatch('sendConfirmationEmail');
                    } else {
                        context.commit('setReservationID', -1);
                    }
                    context.commit('setLoading', false);
                }
                
            });
        },
        async sendConfirmationEmail(context) {
            var bodyFormData = new FormData();
            bodyFormData.append('type', 2);
            bodyFormData.append('lang', context.getters.get_lang_code);
            bodyFormData.append('recipient', context.state.checkout_data.email);
            bodyFormData.append('reservation_id', context.state.reservation_id);

            let summary = ''
            if(context.state.payment_bonifico == 1) {
                summary += `<tr><td colspan="2" style="border: 1px solid #AAAAAA; border-radius: 3px; padding: 10px 14px;"><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tbody><tr><td style="vertical-align: middle">IBAN<br/><b>` + context.state.company_info.iban + `</b></td><td>` + context.getters.get_translation('BANK') + `<br/><b>` + context.state.company_info.banca + `</b></td></tr>`;
                summary += `<tr><td>` + context.getters.get_translation('QUOTA_AMOUNT') + `<br/><b>` + context.state.amount_bonifico + ` €</b></td></tr>`;
                
                summary += `</tr></tbody></table></td></tr>`;

                summary += context.getters.confirmation_html;

            }else {
                summary = context.getters.confirmation_html;
            }

            bodyFormData.append('summary', summary);
            
            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/sendConfirmationEmail/'+context.state.company_id,
                data: bodyFormData
            });
        },
        async getQuotaResult(context, payload){ //mirco
            var self = this;

            const convertDateArray = dates => dates.map(date => date.split("-").reverse().join("-"));

            const formattedCheckin = convertDateArray(payload.quotaCheckin);
            const formattedCheckout = convertDateArray(payload.quotaCheckout);
            const quotaResult = payload.quotaResult;

            var bodyFormData = new FormData();
            quotaResult.forEach((quota, index)=>{
                bodyFormData.append('check_in[]', formattedCheckin[index]);
                bodyFormData.append('check_out[]', formattedCheckout[index]);
                bodyFormData.append('adult[]', quota.NRADULT);
                bodyFormData.append('child1[]', quota.NRCHILD1);
                bodyFormData.append('child2[]', quota.NRCHILD2);
                bodyFormData.append('infant[]', quota.NRINFANT);
                bodyFormData.append('qty[]', quota.QTY);
                bodyFormData.append('room_type[]', quota.IDROOMTYPE);
            });

            return self.$axios({
                method: 'post',
                url: '/booking/'+self.$company+'/getAvailabilityMulti/'+context.state.company_id,
                data: bodyFormData
            }).then(response =>{           
                return response.data;
            });

        },
        async quotaLoadsendConfirmationEmail(context){

            var bodyFormData = new FormData;

            bodyFormData.append('type', 2);
            bodyFormData.append('lang', context.getters.get_lang_code);
            bodyFormData.append('recipient', context.state.quotas[1][0].EMAIL);
            bodyFormData.append('reservation_id', context.state.quotas[1][0].idquota);
            bodyFormData.append('reservation_year', context.state.quotas[1][0].YEAR);

            let summary = ``;

            summary += `<tr><td style="padding: 10px;"><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
            summary += `<tr><td style="width: 50%;"><strong>` + context.getters.get_translation('QUOTA_ID') + `</strong></td><td>`+context.state.quotas[1][0].NUMBER+` / ` + context.state.quotas[1][0].YEAR + `</td></tr>`
            summary += `<tr><td><strong>Totale preventivo</strong></td><td>`+ Vue.prototype.$formatPriceEuro(context.state.quotas_total_price[context.state.quota.nrchoice]) +`</td></tr>`
            summary += `</table></td></tr>`

            if(context.state.quotas[1][0].CONFIRMATIONTYPE == 1){
                summary += `<tr><td style="padding: 10px;"><p>` + context.getters.get_translation('BANK_TRANSFER_REQUEST') + `</p><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
                summary += `<tr><td style="width: 50%;"><strong>IBAN</strong></td><td>` +context.state.quotas[1][0].IBAN + `</td></tr>`
                summary += `<tr><td><strong>` + context.getters.get_translation('BANK') + `</strong></td><td>` + context.state.quotas[1][0].BANCA + `</td></tr>`
                summary += `<tr><td><strong>` + context.getters.get_translation('QUOTA_AMOUNT') + `</strong></td><td>` + Vue.prototype.$formatPriceEuro(context.state.quotas_amount[context.state.quota.nrchoice]) + `</td></tr>`
                summary += `<tr><td><strong>` + context.getters.get_translation('QUOTA_TOMADE') + `</strong></td><td>` + Vue.prototype.$formatDateIt(context.state.quotas[1][0].EXPIRATION) + `</td></tr>`
                summary += `</table></td></tr>`
            }

            summary += `<tr><td style="padding: 10px;"><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
            summary += `<tr><td style="width: 50%;"><strong>` + context.getters.get_translation('GUEST') + `</strong></td><td>` +context.state.quotas[1][0].REFERENCE + `</td></tr>`
            summary += `<tr><td><strong>Contatti</strong></td><td>` + context.state.quotas[1][0].EMAIL + ` / ` + context.state.quotas[1][0].MOBILE + `</td></tr>`
            summary += `</table></td></tr>`

            if(context.state.quotas_emailFooter != null && context.state.quotas_emailFooter != ''){
                summary += `<hr>`
    
                summary += `<tr><td style="padding: 10px;"><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
                summary += `<tr><td>` + context.state.quotas_emailFooter + `</td></tr>`
                summary += `</table></td></tr>`
            }


            bodyFormData.append('summary', summary);

            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/quotaLoadSendConfirmationEmail/'+context.state.company_id,
                data: bodyFormData
            });
        },
        async quotaLoadSendNoDisponibilityMail(context){
            var bodyFormData = new FormData;

            bodyFormData.append('type', 2);
            bodyFormData.append('lang', context.getters.get_lang_code);
            bodyFormData.append('recipient', context.state.quotas[1][0].EMAIL);
            bodyFormData.append('reservation_id', context.state.quotas[1][0].idquota);
            bodyFormData.append('reservation_year', context.state.quotas[1][0].YEAR);

            let summary = ``;

            summary += `<tr><td style="padding: 10px;"><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
            summary += `<tr><td style="width: 50%;"><strong>` + context.getters.get_translation('QUOTA_ID') + `</strong></td><td>`+context.state.quotas[1][0].NUMBER+` / ` + context.state.quotas[1][0].YEAR + `</td></tr>`
            summary += `</table></td></tr>`

            summary += `<tr><td style="padding: 10px;"><table role="presentation" cellspacing="0" cellpadding="5" border="0" width="100%">`
            summary += `<tr><td style="width: 50%;"><strong>` + context.getters.get_translation('GUEST') + `</strong></td><td>` +context.state.quotas[1][0].REFERENCE + `</td></tr>`
            summary += `<tr><td><strong>Contatti</strong></td><td>` + context.state.quotas[1][0].EMAIL + ` / ` + context.state.quotas[1][0].MOBILE + `</td></tr>`
            summary += `</table></td></tr>`

            bodyFormData.append('summary', summary);

            this.$axios({
                method: 'post',
                url: '/booking/'+this.$company+'/quotaLoadSendNoDisponibilityMail/'+context.state.company_id,
                data: bodyFormData
            });
        },
        backToSearch() {
            if(this.$type == 'checkout' || this.$type == 'fail' || this.$type == 'paid') {
                window.location.href = 'https://booking.isidorosoftware.com/' + this.$company + '/';
            } else {
                window.location.reload();
            }
        }
    }
});

new Vue({
    render: h => h(App),
    store: store,
    mounted: function() {
        if(jQuery(window).width() > 1023) {
            /*jQuery('#sidebar').stickySidebar({
                topSpacing: 20,
                bottomSpacing: 20,
                minWidth: 1023
            });*/
        } else {
            jQuery('body').addClass('overflow_hidden');
        }

        var self = this;

        let check_in = this.$formatDate(new Date(Date.now()));
        let check_out = this.$formatDate(new Date(Date.now() + (60*60*24*1000)));

        this.$store.commit('setCheckIn', check_in);
        this.$store.commit('setCheckOut', check_out); 

        self.$axios({
            method: 'get',
            url: '/booking/'+self.$company+'/getCompanyID'
        }).then(function (response) {
            self.$store.commit('setCompanyID', response.data);

            let urlParams = new URLSearchParams(window.location.search);

            if(self.$type != 'quotas' || self.$type != 'requestQuota'){
    
                let company_id = urlParams.get('IDCOMPANY');
                if(company_id != null && company_id != '') {
                    self.$store.commit('setCompanyID', parseInt(company_id));
                }
    
                let lang = urlParams.get('lang');
                if(lang != null) {
                    var lang_id = 1;
                    switch(lang) {
                        case 'IT':
                            lang_id = 0;
                            break;
                        case 'EN':
                            lang_id = 1;
                            break;
                        case 'DE':
                            lang_id = 2;
                            break;
                        case 'FR':
                            lang_id = 3;
                            break;
                        case 'PT':
                            lang_id = 4;
                            break;
                        case 'ES':
                            lang_id = 5;
                            break;
                        case 'RU':
                            lang_id = 6;
                            break;
                        case 'CH':
                            lang_id = 7;
                            break;
                        default:
                            lang_id = 0;
                            break;
                    }
                    self.$store.commit('setLang', lang_id);
                }
            }

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getCompanyInfo/'+self.$store.state.company_id
            }).then(function (response) {
                self.$store.commit('setCompanyInfo', response.data);
            });

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getBackgroundAndColors/'+self.$store.state.company_id
            }).then(function (response) {
                if(response.data.colors != null && response.data.colors.mainColor != null) {
                    document.documentElement.style.setProperty('--mainColor', response.data.colors.mainColor);
                    document.documentElement.style.setProperty('--mainColorTrasparence', response.data.colors.mainColor+'33');
                    document.documentElement.style.setProperty('--litepickerDayIsStartBg', response.data.colors.mainColor);
                    document.documentElement.style.setProperty('--litepickerDayIsEndBg', response.data.colors.mainColor);
                    document.documentElement.style.setProperty('--litepickerDayColorHover', response.data.colors.mainColor);
                    document.documentElement.style.setProperty('--litepickerDayIsInRange', response.data.colors.mainColor+'33');
                } else {
                    document.documentElement.style.setProperty('--mainColor', '#2196f3');
                    document.documentElement.style.setProperty('--mainColorTransparence', '#2196f333');
                    document.documentElement.style.setProperty('--litepickerDayIsStartBg', '#2196f3');
                    document.documentElement.style.setProperty('--litepickerDayIsEndBg', '#2196f3');
                    document.documentElement.style.setProperty('--litepickerDayColorHover', '#2196f3');
                    document.documentElement.style.setProperty('--litepickerDayIsInRange', '#2196f3'+'33');
                }

                if(response.data.colors != null && response.data.colors.backgroundColor != null) {
                    document.documentElement.style.setProperty('--backgroundColor', response.data.colors.backgroundColor);
                } else {
                    document.documentElement.style.setProperty('--backgroundColor', '#eee');
                }
                
                if(response.data.background != '') {
                    document.documentElement.style.setProperty('--backgroundImage', 'url("' + encodeURI(self.$baseURL + '/structures/'+self.$company+'/'+self.$store.state.company_id+'/company/' + response.data.background) + '")');
                }
            });

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getTranslations'
            }).then(function (response) {
                self.$store.commit('setTranslations', response.data);
            });

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getAges'
            }).then(function (response) {
                self.$store.commit('setAges', response.data);
            });

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getRoomTypes'
            }).then(function (response) {
                self.$store.commit('setRoomTypes', response.data);
            });

            self.$axios({
                method: 'get',
                url: '/booking/'+self.$company+'/getTrackingCodes'
            }).then(function (response) {
                self.$store.commit('setTrackingCodes', response.data);
                if(self.$store.state.tracking_codes['TRACKING_SCRIPT_BASE'] != undefined) {
                    eval(self.$store.state.tracking_codes['TRACKING_SCRIPT_BASE']);
                }
            });

            window.onpopstate = function() {
                if(self.$store.state.booking_type == 1) {

                    if(self.$store.state.step == '1') {
                        if(self.$store.state.current_room == 0) {
                            window.location.reload();
                        } else {
                            if(self.$store.state.rooms[self.$store.state.current_room - 1].extra_charges.length > 0) {
                                self.$store.commit('setStep', '2');
                                self.$store.commit('setCurrentRoom', self.$store.state.current_room - 1);
                            } else {
                                self.$store.commit('setStep', '1');
                                self.$store.commit('setCurrentRoom', self.$store.state.current_room - 1);
                                self.$store.dispatch('getSearchResults');
                            }
                        }
                    } else if(self.$store.state.step == '2') {
                        self.$store.commit('setStep', '1');
                        self.$store.dispatch('getSearchResults');
                    } else if(self.$store.state.step == '3') {
                        if(self.$store.state.rooms[self.$store.state.current_room].extra_charges.length > 0) {
                            self.$store.commit('setStep', '2');
                        } else {
                            self.$store.commit('setStep', '1');
                            self.$store.dispatch('getSearchResults');
                        }
                    }

                } else if(self.$store.state.booking_type == 2) {

                    if(self.$store.state.step == '1') {
                        window.location.reload();
                    } else if(self.$store.state.step == '1.1') {
                        self.$store.commit('setStep', '1');
                        self.$store.commit('setPackageCalendar', '');
                    } else if(self.$store.state.step == '1.2') {
                        self.$store.commit('setStep', '1.1');
                        self.$store.commit('setPackageRooms', '');
                    } else if(self.$store.state.step == '2') {
                        self.$store.commit('setStep', '1.2');
                        self.$store.commit('setExtraCharges', '');
                    } else if(self.$store.state.step == '3') {
                        if(self.$store.state.extra_charges == '') {
                            self.$store.commit('setStep', '1.2');
                        } else {
                            self.$store.commit('setStep', '2');
                        }
                    }

                }
            }

            if(self.$type == 'checkout') {

                let params = window.location.pathname.split('/').slice(1)
                let reservation_id = params[2]

                self.$axios({
                    method: 'get',
                    url: '/booking/'+self.$company+'/getReservationDump/'+reservation_id
                }).then(function (response) {
                    var res = response.data;
                    self.$store.replaceState(JSON.parse(res));

                    self.$store.commit('setStep', 3);

                    self.$axios({
                        method: 'get',
                        url: '/booking/'+self.$company+'/getTranslations'
                    }).then(function (response) {
                        self.$store.commit('setTranslations', response.data);

                        self.$store.commit('setLoading', false);
                    });
                });

            } else if(self.$type == 'fail') {

                let params = window.location.pathname.split('/').slice(1)
                let reservation_id = params[2]

                self.$axios({
                    method: 'get',
                    url: '/booking/'+self.$company+'/getReservationDump/'+reservation_id
                }).then(function (response) {
                    var res = response.data;
                    self.$store.replaceState(JSON.parse(res));

                    self.$store.commit('setStep', 3);

                    self.$axios({
                        method: 'get',
                        url: '/booking/'+self.$company+'/getTranslations'
                    }).then(function (response) {
                        self.$store.commit('setTranslations', response.data);

                        self.$store.commit('setPaymentError', self.$store.getters.get_translation('PAYMENT_FAILED'));

                        self.$store.commit('setLoading', false);
                    });
                });

            } else if(self.$type == 'paid') {

                let params = window.location.pathname.split('/').slice(1)
                let reservation_id = params[2]

                self.$axios({
                    method: 'get',
                    url: '/booking/'+self.$company+'/getReservationDump/'+reservation_id
                }).then(function (response) {
                    var res = response.data;
                    self.$store.replaceState(JSON.parse(res));

                    self.$axios({
                        method: 'get',
                        url: '/booking/'+self.$company+'/getTranslations'
                    }).then(function (response) {
                        self.$store.commit('setTranslations', response.data);

                        if(reservation_id != '' && !isNaN(reservation_id) && reservation_id > 0) {
                            self.$store.commit('setReservationID', reservation_id);
                            self.$store.dispatch('sendConfirmationEmail');
                        } else {
                            self.$store.commit('setReservationID', -1);
                        }

                        self.$store.commit('setLoading', false);
                    });
                });

            }else if(self.$type == 'quotas' || self.$type == 'requestQuota') {
                return
            } else {

                let company_id = urlParams.get('IDCOMPANY');
                if(company_id != null && company_id != '') {
                    self.$store.commit('setCompanyID', company_id);
                }

                let lang = urlParams.get('lang');
                if(lang != null && lang != '') {
                    if(lang == 'IT') {
                        self.$store.commit('setLang', 0);
                    } else if(lang == 'EN') {
                        self.$store.commit('setLang', 1);
                    } else if(lang == 'DE') {
                        self.$store.commit('setLang', 2);
                    } else if(lang == 'FR') {
                        self.$store.commit('setLang', 3);
                    } else if(lang == 'PT') {
                        self.$store.commit('setLang', 4);
                    } else if(lang == 'ES') {
                        self.$store.commit('setLang', 5);
                    } else if(lang == 'RU') {
                        self.$store.commit('setLang', 6);
                    } else if(lang == 'CH') {
                        self.$store.commit('setLang', 7);
                    }
                    
                }

                let check_in = urlParams.get('IN');
                if(check_in != null && check_in != '') {
                    check_in = check_in.split("/").reverse().join("-");
                    let check_in_date = new Date(check_in);
                    if(check_in_date instanceof Date && !isNaN(check_in_date)) {
                        self.$store.commit('setCheckIn', self.$formatDate(check_in_date))
                    }
                }

                let check_out = urlParams.get('OUT');
                if(check_out != null && check_out != '') {
                    check_out = check_out.split("/").reverse().join("-");
                    let check_out_date = new Date(check_out);
                    if(check_out_date instanceof Date && !isNaN(check_out_date)) {
                        self.$store.commit('setCheckOut', self.$formatDate(check_out_date))
                    }
                }

                let adult = urlParams.get('R1_ADULT');
                if(adult == null) {
                    adult = urlParams.get('ROOM1_ADULT');
                }
                if(adult != null && adult != '' && !isNaN(adult)) {
                    let index = 0;
                    let adults = parseInt(adult);
                    self.$store.commit('setAdults', {index, adults});
                }

                let child = urlParams.get('R1_CHILD');
                if(child == null) {
                    child = urlParams.get('ROOM1_CHILD');
                }
                if(child != null && child != '' && !isNaN(child)) {
                    let index = 0;
                    let child1 = parseInt(child);
                    self.$store.commit('setChild1', {index, child1})
                }

                let child_2 = urlParams.get('R1_CHILD2');
                if(child_2 == null) {
                    child_2 = urlParams.get('ROOM1_CHILD2');
                }
                if(child_2 != null && child_2 != '' && !isNaN(child_2)) {
                    let index = 0;
                    let child2 = parseInt(child_2);
                    self.$store.commit('setChild2', {index, child2})
                }

                let infant = urlParams.get('R1_INFANT');
                if(infant == null) {
                    infant = urlParams.get('ROOM1_INFANT');
                }
                if(infant != null && infant != '' && !isNaN(infant)) {
                    let index = 0;
                    let infants = parseInt(infant);
                    self.$store.commit('setInfants', {index, infants})
                }

                let rooms_num = urlParams.get('ROOMS_NUM');
                if(rooms_num != null && rooms_num != '' && !isNaN(rooms_num)) {
                    for(let i = 2; i <= rooms_num; i++) {
                        self.$store.commit('addRoom');

                        let adult = urlParams.get('R'+i+'_ADULT');
                        if(adult == null) {
                            adult = urlParams.get('ROOM'+i+'_ADULT');
                        }
                        if(adult == null) {
                            adult = urlParams.get('R1_ADULT');
                        }
                        if(adult == null) {
                            adult = urlParams.get('ROOM1_ADULT');
                        }
                        if(adult != null && adult != '' && !isNaN(adult)) {
                            let index = i-1;
                            let adults = parseInt(adult);
                            self.$store.commit('setAdults', {index, adults});
                        }

                        let child = urlParams.get('R'+i+'_CHILD');
                        if(child == null) {
                            child = urlParams.get('ROOM'+i+'_CHILD');
                        }
                        if(child == null) {
                            child = urlParams.get('R1_CHILD');
                        }
                        if(child == null) {
                            child = urlParams.get('ROOM1_CHILD');
                        }
                        if(child != null && child != '' && !isNaN(child)) {
                            let index = i-1;
                            let child1 = parseInt(child);
                            self.$store.commit('setChild1', {index, child1})
                        }

                        let child_2 = urlParams.get('R'+i+'_CHILD2');
                        if(child_2 == null) {
                            child_2 = urlParams.get('ROOM'+i+'_CHILD2');
                        }
                        if(child_2 == null) {
                            child_2 = urlParams.get('R1_CHILD2');
                        }
                        if(child_2 == null) {
                            child_2 = urlParams.get('ROOM1_CHILD2');
                        }
                        if(child_2 != null && child_2 != '' && !isNaN(child_2)) {
                            let index = i-1;
                            let child2 = parseInt(child_2);
                            self.$store.commit('setChild2', {index, child2})
                        }

                        let infant = urlParams.get('R'+i+'_INFANT');
                        if(infant == null) {
                            infant = urlParams.get('ROOM'+i+'_INFANT');
                        }
                        if(infant == null) {
                            infant = urlParams.get('R1_INFANT');
                        }
                        if(infant == null) {
                            infant = urlParams.get('ROOM1_INFANT');
                        }
                        if(infant != null && infant != '' && !isNaN(infant)) {
                            let index = i-1;
                            let infants = parseInt(infant);
                            self.$store.commit('setInfants', {index, infants})
                        }
                    }
                }

                let package_id = urlParams.get('package');
                if(package_id != null && package_id != '') {
                    self.$store.commit('setBookingType', 2);
                    self.$store.dispatch('getPackagesSearchResults');
                } else {
                    self.$store.dispatch('getSearchResults');
                }
            }
        });
    }
}).$mount('#app')